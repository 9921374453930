@use './vars' as *;
@use './styles' as *;
@use './mixins' as *;

.etp-global {
  display: contents;

  .gray + .gray {
    border-top: 1px solid $gray-500;
  }

  .white + .white {
    border-top: 1px solid $gray-300;
  }

  .black + .black {
    border-top: 1px solid $gray-900;
  }

  .etp-form {

    .etp-input-group {
      display: flex;
      flex-direction: column;

      label {
        font-size: 12px;
        padding-bottom: 5px;
        text-transform: uppercase;
      }

      input {
        padding: 0 15px;
        flex: 0 0 50px;
        background-color: transparent;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .black,
  .blue {
    .etp-form {

      .etp-input-group {

        input {
          border: 1px solid $white;
          color: $white;

          &::placeholder {
            color: rgba($white, 0.8);
          }
        }
      }
    }
  }

  .gray,
  .white {
    .etp-form {

      .etp-input-group {

        input {
          border: 1px solid $black;
          color: $black;
          position: relative;

          &:focus {
            margin: 0;
            border: 2px solid $midnight_blue;
            padding-left: 14px;
          }

          &::placeholder {
            color: $gray-800;
          }
        }
      }

      .ms-Dropdown {

        .ms-Dropdown-title {
          border-radius: 0;

          &.ms-Dropdown-titleIsPlaceHolder {
            color: $gray-800;
          }
        }
      }
    }
  }

  .etp-label {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .black {
    .etp-label {
      color: $gray-300;
    }
  }

  .white,
  .gray {
    .etp-label {
      color: $gray-700;
    }
  }

  .gray {
    .etp-input-group {

      input {
        border: 1px solid $black;
        color: $black;

        &::placeholder {
          color: $black;
        }
      }
    }
  }

  .etp-top-border {
    border-top-width: 1px;
    border-top-style: solid;
    padding-top: 40px;
  }

  .blue,
  .black {
    .etp-top-border {
      border-color: $white;
    }
  }

  .white {
    .etp-top-border {
      border-color: $gray-400;
    }
  }

  .gray {
    .etp-top-border {
      border-color: $black;
    }
  }

  .font-size-12 {
    font-size: 12px;
  }

  .font-size-14 {
    font-size: 14px;
  }

  .font-size-16 {
    font-size: 16px;
  }

  .font-size-18 {
    font-size: 18px;
  }

  @include media-breakpoint-up(sm) {
    .container {
      padding-left: 28px;
      padding-right: 28px;

      .row {
        margin-left: -28px !important;
        margin-right: -28px !important;

        & > * {
          padding-right: 28px !important;
          padding-left: 28px !important;
        }
      }
    }
  }
}

