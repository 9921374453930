@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.etp-quick-navigation {
  z-index: 3;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  background-color: $gray-100;
  padding: 0 40px;
  height: 70px;
  align-items: stretch;

  @include media-breakpoint-up(lg) {
    padding: 0 80px;
  }

  .etp-quick-navigation-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
  }

  > a {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: $black;
    text-decoration: none;
  }

  @include media-breakpoint-up(md) {
    display: flex;
  }

  .etp-quick-navigation-title {
    font-size: 12px;
    color: $gray-800;
  }
}
