@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.document-list {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    // h5 {
    //     // padding-bottom: 40px;
    // }
	.document {
		text-decoration: none;
	}

	.row {
        row-gap: 20px !important;

        .document-content {
            // display: flex;
            // flex-wrap: wrap;
            .document-content-additional {
                padding-right: 30px;
                padding-bottom: 40px;
                width:100%;
                display: flex;
                flex-direction: column;
                row-gap: 30px;
                column-gap: 30px;

                @include media-breakpoint-up(md) {
                    flex-direction: row;
                }

                @include media-breakpoint-up(lg) {
                    width: auto;
                }
            }
            .document-content-main {
                // display: flex;
                flex: 1;
            }
        }
        .documents {
            padding-bottom: 30px;
        }
		.document {
			display: flex;
			align-items: center;
            justify-content: space-between;

            padding: 30px 20px;

            border: 1px solid $black;

            &.bottom {
                border: 0;
                border-bottom: 1px solid black;
                padding: 16px 0;
                text-transform: none;
            }

            display: flex;
            align-items: center;
            column-gap: 10px;

            .black & {
                border-color: $gray-900;

                &:hover {
                    background: $gray-900;
                }
            }
            
		}
	}
}
