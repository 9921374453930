@use '../../styles/vars' as *;

.faqs-title {
  line-height: 1;
  font-size: inherit;
}

.etp-faqs {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.etp-faq {
  display: flex;
  flex-direction: column;

  &.etp-faq-open {
    .etp-answer {
      display: initial;
    }

    .etp-faq-chevron {
      transform: rotateZ(90deg);
    }
  }



  .etp-faq-question {
    display: flex;
    align-items: center;
    column-gap: 25px;
    cursor: pointer;
  }

  .etp-answer {
    margin: 24px 0 0 40px;
    padding: 0 30px;
    border-left: 1px solid $gray-300;
    display: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    p {
      margin: 0 0 20px 0;
    }
  }
}