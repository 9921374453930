@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.etp-bitcoin-effect-text {
  > * {
    margin: 20px 0;
  }

  .etp-bitcoin-effect-subtext {
    font-size: 14px;
    margin: 0;
    color: $gray-800;
  }
}

.etp-portfolio {
  display: flex;
  margin: 80px 0;

  .etp-portfolio-columns {
    display: flex;
    flex: 3 1 auto;
    overflow-y: auto;
  }

  .etp-portfolio-column {
    flex: 1 1 auto;
    min-width: 230px;
    max-width: 450px;

    &.etp-portfolio-column-keys {
      flex: 1 3 auto;
      min-width: 120px;

      .etp-portfolio-row {
        padding-left: 0;
      }
    }

    .etp-portfolio-header {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      padding: 0 20px;
      height: 230px;

      img {
        height: 74px;
        width: 74px;
      }

      h5 {
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0;
      }
    }

    .etp-portfolio-row {
      height: 70px;
      padding: 10px 20px;
      border-top: 1px solid $gray-300;

      &.etp-portfolio-row-key {
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
  }
}