@use '../styles/mixins' as *;

.pattern-wrapper {
  display: block;
  position: relative;
  z-index: 0;
}

.pattern {
  
  position: absolute;
  // z-index: -1;
}

.pattern-top-right {
  top: 0;
  right: calc(-40px + 28px);
}

.pattern-top-left {
  top: 40px;
  left: calc(-40px + 28px);
}

.pattern-bottom-left {
  bottom: -81px;
  left: calc(-40px + 28px);
}

.pattern-bottom {
  bottom: -40px;
  left: calc(-40px + 28px);

  .is-home & {
    bottom: -80px;
  }
}

.theme-green {

  .header > .logo,
  .navigation-menu-icon,
  .breadcrumb img {
    filter: invert(1);
  }
  .pattern-bottom-left {
    bottom: 0;
    left: calc(-40px + 28px);
  }

  &.is-home {
    .pattern-bottom-left {
      bottom: -120px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .pattern-top-right {
    right: calc(-80px + 28px);
  }
  .pattern-bottom-right {
    bottom: -80px;
    right: calc(-80px + 28px);
  }
  .pattern-top-left {
    top: 0;
    left: calc(-80px + 28px);
  }
  .pattern-bottom-left {
    left: calc(-80px + 28px);
    bottom: -161px;
  }
  
  .pattern-bottom {
    bottom: -80px;
    left: calc(-80px + 28px);

    .is-home & {
      bottom: -160px;
    }
  }

  .theme-black {
    .pattern-top-right {
      top: 160px;
    }
  }
  .theme-green {
    .pattern-bottom-left {
      left: calc(-80px + 28px);
    }
    &.is-home {
      .pattern-bottom-left {
        bottom: -240px;
      }
    }
  }
}


@media (max-width: 767px) {
  .pattern-wrapper.mobile-ban{
    display: none;
  }
}
