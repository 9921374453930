@use '../../styles/vars' as *;

.white {
  .etp-product-card {
    border: 1px solid $gray-300;

    .etp-product-card-data {

      .etp-product-card-row {
        &:not(:first-of-type) {
          border-top: 1px solid $gray-300;
        }
      }
    }
  }
}

.etp-product-card {
  flex: 1 1 400px;
  height: 100%;
  min-width: 340px;
  max-width: 420px;
  padding: 40px;
  border: 1px solid $gray-900;
  text-decoration: none;
  display: flex;
  flex-direction: column;

  > img {
    align-self: flex-start;
  }

  .etp-product-card-title {
    display: flex;
    column-gap: 30px;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0;
    cursor: pointer;

    &:hover {
      img {
        transform: translateX(10px);
      }
    }

    img {
      margin-left: 10px;
      transition: .3s transform ease-in;
    }
  }

  .etp-product-card-data {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .etp-product-card-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;
      padding: 10px 0;
      font-size: 14px;

      &:not(:first-of-type) {
        border-top: 1px solid $gray-900;
      }

      > span:first-of-type {
        font-weight: bold;
      }

      .etp-product-card-value-copy {
        cursor: pointer;
      }
    }
  }
}