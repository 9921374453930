@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.black,
.blue {
  .logo-list {
    color: $white;

    .logo-list-logos {
      filter: brightness(0) invert(1);
    }
  }
}

.white,
.gray {
  .logo-list {
    color: $black;

    .logo-list-logos {
      filter: brightness(0) invert(0.7);
    }

    .logo-list-text {
      color: $gray-700;
    }
  }
}

.logo-list {
  padding-top: 50px;
  color: $gray-700;

  .logo-list-text {
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 500;
    font-size: 12px;
    margin-top: 6px;
  }

  .logo-list-logos {
    display: grid;
    grid-auto-rows: 30px;
    grid-template-columns: repeat(2, fit-content(50px));
    justify-content: flex-start;
    align-items: center;
    column-gap: 40px;
    row-gap: 40px;
    flex-wrap: wrap;

    > img {
      max-width: 130px;
    }

    @include media-breakpoint-up(sm) {
      grid-auto-rows: 40px;
      grid-template-columns: repeat(3, fit-content(70px));
      justify-content: space-between;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(5, fit-content(70px));
    }
  }
}