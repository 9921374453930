@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.feature-statements {
	margin-top: 40px;
    @include media-breakpoint-up (md) {
        margin-top: 60px;
    }

	&.row {
		row-gap: 40px;
	}

	.feature-statement {
        opacity: 0;
		transform: translateY(50px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 20px 0 0;
		gap: 10px;

		// background: linear-gradient(127.72deg, $midnight_blue 1%, rgba($midnight_blue, 0.5) 100%);

		border-top: 1px solid #ffffff;
		border-radius: 0px;

		.count {
			
			font-style: normal;
			font-size: 14px;
			line-height: 20px;
			color: $gray-700;
			margin-bottom: 10px;
		}

		.description {
			color: $gray-900;
		}
        &.animate-ease-up {
            transform: translateY(0);
            opacity: 1;
        }
        
	}

	@include media-breakpoint-up(lg) {
		.feature-statement {
			.count {
				margin-bottom: 30px;
			}
		}
	}
}

.white,
.gray {
	.feature-statements {
		.feature-statement {
			border-color: $black;
		}
	}
}