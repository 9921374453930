@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.exchange-list {
	@include widget;

	.exchanges {
		display: flex;
		gap: 40px;
		padding-top: 100px;
		padding-bottom: 40px;

        
		.exchange {
			display: flex;
			flex-direction: column;
			align-items: center;
            @include media-breakpoint-up(xs) {
                min-width: 80%;
            }
			
            @include media-breakpoint-up(lg) {
                min-width: 25%;
            }
			padding: 40px;
            padding-top: 75px;
			background-color: white;

			.exchange-logo {
				display: flex;
				flex: 1;
                margin-bottom: 70px;

				img {
					align-self: center;
					justify-self: center;
                    max-height: 70px;
				}
			}

			.exchange-title {
				
				font-style: normal;
				font-weight: 700;
				font-size: 20px;
				line-height: 30px;
				text-align: center;
				letter-spacing: 0.02em;
			}
		}
	}
}
