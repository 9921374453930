@use '../../styles/vars' as *;

.etp-timecards {
  display: grid;
  grid-template-rows: 240px;
  grid-auto-columns: 340px;
  column-gap: 80px;
}

.etp-timecard {
  grid-row: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 40px;
  border-left-width: 1px;
  border-left-style: solid;
  white-space: normal;

  ul {
    padding-left: 20px;
    margin: 0;

    li {
      margin-top: 6px;
    }
  }
}

.blue,
.black {
  border-left-color: $white;
}

.gray,
.white {
  border-left-color: $gray-700;
}