@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.etp-xbt-list-products {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;

  .h2 {
    margin-right: auto;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 73px;
    }
  }
}

.etp-card-disclaimer {
  padding-left: 28px;
  padding-right: 28px;

  @include media-breakpoint-up(xl) {
    padding-left: 128px;
    padding-right: 85px;
  }
  @include media-breakpoint-up(xxl) {
    padding-left: 180px;
    padding-right: 163px;
  }
}

.etp-xbt-list-products-card {
  justify-content: space-between;
  margin-bottom: 108px;

  .h2 {
    padding-right: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 90px;
    }

    @include media-breakpoint-up(xl) {
      padding-right: 40px;
      margin-left: 70px;
    }
  }

  br {
    display: none;

    @include media-breakpoint-up(xl) {
      display: block;
    }
  }
  
  .etp-xbt-list-title {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    gap: 0;
    flex-grow: 1;

    @include media-breakpoint-up(xl) {
      margin-left: 100px;
    }

    @include media-breakpoint-up(xxl) {
      margin-left: 149px;
    }
  }

  .etp-product-card {
    margin-right: 55px;

    @include media-breakpoint-up(xxl) {
      margin-right: 135px;
    }
  }
  
}

.etp-xbt-list-title {
  flex: 1 1 400px;
  height: 100%;
  min-width: 340px;
  max-width: 420px;
  .not-available-message {
    margin-top: 80px;
  }
  p:first-child {
    margin-top: 2px;
  }
}

.etp-xbt-list-more-info {
  flex: 1 1 400px;
  min-height: 380px;
  min-width: 340px;
  max-width: 420px;
  padding: 40px;
  background-color: $gray-950;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > a {
    align-self: flex-end;
  }
}
