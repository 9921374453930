@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

@include media-breakpoint-up(xs) {
	.card {
		padding: 20px;
    opacity: 0;
	}
}

// @include media-breakpoint-up(sm) {
// 	.card {
// 		margin-top: 40px;
// 	}
// }

.card {
	border: 1px solid white;
	cursor: pointer;
	background: rgba($midnight_blue, 0.7);
	display: flex;
	gap: 40px;
	text-decoration: none;
  transition: background-color 0.3s linear, border-radius 0.3s linear, color 0.3s linear;

	.icon-foward {
		transition: .3s transform ease-in;
	}

	&:hover {
		.icon-foward {
			transform: translate(8px, 0);
		}
	}

	.card-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 5px;

		.card-amount-title {
			font-weight: 500;
			font-size: 10px;
			line-height: 15px;
			color: $gray-900;
			display: flex;
			align-items: flex-end;
			letter-spacing: 0.02em;
            text-transform: uppercase;
		}

		.card-amount {
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			text-transform: uppercase;
			display: flex;
			gap: 5px;
			flex-wrap: wrap;
			align-content: baseline;
		}

		.card-amount-unit {
			font-weight: 100;
		}

		// .more-info {
		// 	position: relative;
		// 	height: 28px;
    //         
    //         font-style: normal;
    //         font-weight: 700;
    //         font-size: 12px;
    //         line-height: 14px;
    //         display: flex;
    //         align-items: center;
    //         letter-spacing: 0.1em;
    //         text-transform: uppercase;
		// 	&::before,
		// 	&::after {
		// 		content: '';
		// 		position: absolute;
    //             transition: border-color 0.3s linear, color 0.3s linear;
		// 	}

		// 	&::before {
		// 		top: 0;
		// 		right: 0;
		// 		border-left: 14px solid white;
		// 		border-top: 14px solid transparent;
		// 		border-bottom: 14px solid transparent;
		// 	}

		// 	&::after {
		// 		right: 2px;
		// 		top: 2px;
		// 		border-left: 12px solid $midnight_blue;
		// 		border-top: 12px solid transparent;
		// 		border-bottom: 12px solid transparent;
		// 	}
		// }
	}

	// &.no-hover {
	// 	padding: 40px;
  //       flex-direction: column;

  //       @include media-breakpoint-up(lg) {
  //           flex-direction: row;
  //       }

	// 	.card-content {
	// 		flex: 1;
	// 		position: relative;
	// 	}

	// 	.card-amount-title {
	// 		height: auto;
	// 		text-transform: uppercase;
			
	// 		font-style: normal;
	// 		font-weight: 700;
	// 		font-size: 12px;
	// 		line-height: 12px;
	// 	}

	// 	// .card-amount {
	// 	// 	height: auto;
  //   //         min-height: auto;
  //   //         flex: 1;
	// 	// 	font-family: 'alliance_no_2';
	// 	// 	font-style: normal;
	// 	// 	font-weight: 500;
	// 	// 	font-size: 24px;
	// 	// 	line-height: 28px;

	// 	// 	@include media-breakpoint-up(lg) {
	// 	// 		font-size: 32px;
	// 	// 		line-height: 38px;
	// 	// 	}
	// 	// }

	// 	.card-forward {
	// 		position: absolute;
	// 		right: 0;
	// 		bottom: 0;
            
  //           font-style: normal;
  //           font-weight: 700;
  //           font-size: 12px;
  //           line-height: 14px;
  //           display: flex;
  //           align-items: center;
  //           justify-content: space-between;
  //           letter-spacing: 0.1em;
  //           text-transform: uppercase;
  //           left: 0px;
	// 		img {
	// 			height: 32px;
	// 		}
	// 	}
	// }

	.card-description {
		font-size: 14px;
		line-height: 20px;
		flex: 1;
		padding-bottom: 30px;
		padding-top: 30px;
		margin-bottom: 16px;
	}

	// &:hover:not(.no-hover) {
	// 	background: white;
	// 	color: $midnight_blue;
	// 	border-radius: 40px;

	// 	.more-info {
	// 		&::before {
	// 			border-left-color: $midnight_blue;
	// 		}
	// 		&::after {
	// 			border-left-color: white;
	// 		}
	// 	}
	// }
}

.white,
.gray {
	.card {
		background: none;
		border-color: $gray-300;
	}
}
.white {
	.card {
		background: $white;
		&:hover {
			background: $gray-0;
		}
	}
}
.gray {
	.card {
		&:hover {
			background: $gray-100;
		}
	}
}

@include media-breakpoint-up(lg) {
	.card {
		padding: 40px;

		.card-content {
			.card-amount-title {
				font-size: 12px;
			}
		}
	}
}
