@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.etp-article-categories {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;

  .etp-article-categories-category {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: baseline;

    h3 {
      font-size: 16px;
      padding-right: 20px;
    }

    div {
      display: none;
    }
  }

  @include media-breakpoint-up(sm) {
    gap: 24px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;

    .etp-article-categories-category {
      width: 150px;
      height: 150px;
      border: 1px solid $black;
      padding: 10px;
      flex-direction: column;
      justify-content: space-between;
      align-items: initial;
      text-decoration: none;
      transition: background-color 150ms linear;

      &:hover {
        background-color: $gray-100;
      }

      h3 {
        font-size: 18px;
        margin: 0;
        line-height: 1.2;
      }

      div {
        text-align: right;
        font-size: 14px;
        display: initial;
      }
    }
  }
}

