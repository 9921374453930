@use '../../styles/mixins' as *;

.mobile-image {
    display: initial;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.desktop-image {
    display: none;
    @include media-breakpoint-up(lg) {
        display: initial;
    }
}