@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

@mixin mobile-menu-navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  row-gap: 0;

  background-color: $black;
  z-index: 1;

  .image-link {
    display: initial;
    visibility: visible;
  }

  > .navigation-item,
  > .language-selector-container {
    padding: 30px 30px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      inset: 0 30px;
      border-top: 1px solid rgba(white, 0.6);
      pointer-events: none;
    }
  }

  button {
    padding: 30px 0;
  }

  .navigation-item.navigate {
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding: 0px 30px;

    & > a,
    & > span {
      padding: 30px 0;
    }

    &.sub {
      gap: 15px;
      align-items: center;
    }

    .navigate-forward {
      display: initial;
      height: 23px;
      margin-top: -5.5px;
      margin-bottom: -5.5px;

      &.reverse {
        transform: rotateY(180deg);
      }
    }
  }

  //> .language-selector-container,
  > a:last-of-type:after {
    border-bottom: 1px solid rgba(white, 0.6);
  }
}

@include media-breakpoint-up(xs) {
  .container {
    padding-left: 28px;
    padding-right: 28px;
    position: relative;
    z-index: 1;

    .row {
      margin-left: -28px !important;
      margin-right: -28px !important;
      row-gap: 28px;

      & > * {
        padding-right: 28px !important;
        padding-left: 28px !important;
      }
    }
  }

  .header-wrapper {
    position: absolute;
    width: 100%;
    z-index: 2;

    &.is-fixed {
      position: relative;
    }
  }

  .header,
  .navigation-header {
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    min-height: 75px;

    .theme-green & {
      color: $black;

      .navigation-item {
        color: $black;
      }
    }

    .logo {
      img {
        height: clamp(18px, 5vw, 28px);
      }
    }
  }

  .navigation-menu {
    cursor: pointer;
  }

  .navigation {
    display: none;

    .navigation-item, button {
      cursor: pointer;
      font-family: 'alliance_no_2';
      color: white;
      white-space: nowrap;
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.08em;
      text-transform: uppercase;

      a {
        text-decoration: none;
      }
    }

    & + .language-selector-container {
      display: none;
    }

    &.open {
      @include mobile-menu-navigation;

      .navigation-item {
        display: flex;
        gap: 15px;
        align-items: center;

        img {
          height: 35px
        }
      }
    }
  }

  .navigation-container {
    .navigation {
      .image-link {
        display: none;
        visibility: hidden;
      }
    }
  }

  .navigation-container.is-open {
    // div:not(.right-navigation) {
    > .navigation {
      @include mobile-menu-navigation;
      position: fixed;
      inset: 0;
      overflow-y: auto;
      padding-top: 100px;
      color: $white;
    }

    .navigation-item {
      color: $white;
    }

    .navigation-menu-icon {
      display: none;
    }

    .navigation-menu-close {
      position: relative;
      width: calc(20px / 2);
      height: 20px;

      &:before,
      &:after {
        position: absolute;
        content: ' ';
        height: 20px;
        width: 2px;
        border-radius: 2px;
        background-color: #fff;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    .navigation-header {
      padding: 0px 30px;
      margin-bottom: 40px;
    }
  }

  .footer {
    padding-top: 80px;
    padding-bottom: 60px;
    // color: white;


    .social-media {
      align-items: flex-end;
      margin-top: 30px;
    }

    .navigation {
      display: flex;
      row-gap: 30px;
      flex-direction: column;
      width: 100%;
    }

    .logo {
      // height: 30px;
      margin-bottom: 40px;
      padding-bottom: 60px;
      border-bottom: 1px solid $gray-800;

      img {
        height: 20px;
      }
    }

  }
  .footer-navigation {
    margin-bottom: 40px;
    padding-bottom: 60px;
    border-bottom: 1px solid $gray-800;
  }

  .copyright {
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    color: $gray-800;
  }

  .asset-summary {
    // gap: 50px;
    padding-top: 16px;
    padding-bottom: 16px;

    .asset {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 5px;

      .asset-title,
      .asset-amount {
        font-size: 14px;
        line-height: 20px;
      }

      .asset-source {
        background: $white;
        color: $black;
        font-size: 10px;
        line-height: 15px;
        text-align: right;
        padding: 2px 4px;
        display: inline-block;
      }

      .asset-amount {
        font-weight: 600;
      }

      .asset-indicator {
        vertical-align: text-top;

        &.negative {
          transform: rotateX(180deg);
        }
      }

      &:not(:first-child) {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .container {
    padding-left: 28px;
    padding-right: 28px;

    .row {
      margin-left: -28px !important;
      margin-right: -28px !important;

      & > * {
        padding-right: 28px !important;
        padding-left: 28px !important;
      }
    }

    // .container {
    // 	padding-left: 15px;
    //     padding-right: 15px;

    .row {
      margin-left: -15px !important;
      margin-right: -15px !important;

      & > * {
        padding-right: 15px !important;
        padding-left: 15px !important;
      }
    }

    // }
  }
}


@include media-breakpoint-up(xl) {
  .header {
    height: 100px;

    .navigation-menu {
      display: none;
    }

    .right-navigation {
      display: flex;
      column-gap: 40px;

      .navigation {
        justify-content: flex-end;
        align-items: center;
      }
    }

    .navigation {
      display: flex;
      position: initial;
      flex-direction: row;
      gap: 40px;
      row-gap: 0;
      align-items: center;

      .navigation {
        display: none;
      }

      .navigation-item {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 3px solid transparent;

        .navigate-forward {
          display: none;
        }

        &:hover {
          border-bottom-color: $white;
        }

        &.active {
          color: $white;
          border-bottom-color: $white;
        }

        .theme-green & {
          &:hover {
            border-bottom-color: $black;
          }
        }
      }

      .navigation-mobile {
        display: none;
      }

      .language-selector-container + .container {
        display: none;
      }

      .footer {
        display: none;
      }
    }
  }

  .navigation {
    .navigation-item {
      transition: border-bottom-color 0.4s linear, color 0.4s linear;

      &.active {
        color: white;
      }
    }
  }

  .asset-summary {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    .asset {
      width: 33%;
      row-gap: 0px;
      flex-direction: column;
      align-items: flex-start;

      &:not(:first-child) {
        display: flex;
      }

      .asset-amount {
        display: inline-block;
        margin-right: 8px;
      }
    }
  }

  .footer {
    padding-top: 110px;
    padding-bottom: 60px;

    grid-template:
			'logo navigation'
			'logo social';

    row-gap: 20px;

    .navigation {
      flex-direction: row;
      column-gap: 40px;
      justify-content: flex-start;
      align-items: center;
      height: 30px;
    }

    .logo {
      margin-bottom: 50px;
      padding-bottom: 80px;

      img {
        height: 40px;
      }
    }

    .social-media {
      justify-content: flex-end;
      margin: 0;

      .image-link {
        height: 30px;
        padding: 0px 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.4s linear;

        a path {
          transition: fill 0.4s linear;
        }

        &:hover {
          background-color: white;

          a path {
            fill: $midnight_blue;
          }
        }
      }
    }
  }
  .footer-navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    padding-bottom: 50px;
  }
}

@include media-breakpoint-up(xl) {
  .footer {
    .navigation {
      column-gap: 64px;
    }
  }
}

// @include media-breakpoint-up(xxl) {
// 	.header {
// 	}
// }

.left-navigation .navigation .navigation-item:first-child {
  padding-left: 0px;
}

.navigation .navigation-item:last-child {
  padding-right: 0px;
}

.left-navigation .navigation,
.right-navigation .navigation {
  flex-wrap: wrap;
}

.language-selector {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  gap: 10px;
  cursor: pointer;
}

.two-column-row-split {
  .top-left,
  .bottom-left,
  .right-column {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .top-left,
  .bottom-left {
    border-bottom: 1px solid rgba($dark_blue, 0.25);
  }

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template:
			'topleft right'
			'bottomleft right';

    .top-left,
    .bottom-left,
    .right-column {
      padding: 120px 90px;
    }

    .top-left,
    .bottom-left {
      padding-left: 0px;
    }

    .right-column {
      padding-right: 0px;
    }
    .top-left {
      grid-area: topleft;
    }

    .bottom-left {
      grid-area: bottomleft;
      border-bottom-width: 0px;
    }

    .right-column {
      grid-area: right;
      border-left: 1px solid rgba($dark_blue, 0.25);
    }
  }
}

.navigation-container > .navigation {
  align-items: center;

  & > * {
    width: 100%;
    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
    @media (min-width: 1400px) {
      max-width: 1320px;
    }
  }
}


.share-button {
  background-color: transparent;
  border: 0 none;
  padding: 0;
  outline: none;
  cursor: pointer;
  position: relative;

  .close-icon {
    display: none;
  }

  &.close {
    .close-icon {
      display: initial;
      position: absolute;
      right: 9px;
      top: 8px;
      background: $midnight_blue;
      height: 16px;
      width: 16px;

      &:before,
      &:after {
        position: absolute;
        content: ' ';
        height: 16px;
        width: 2px;
        background-color: white;
        transition: background-color 0.3s linear;
        border-radius: 2px;
      }

      &:before {
        transform: rotateZ(45deg);
      }

      &:after {
        transform: rotateZ(-45deg);
      }
    }

  }

  &.alternate.close {
    .close-icon {
      background-color: white;

      &:before,
      &:after {
        background-color: $midnight_blue;
      }

    }
  }
}

.share-dropdown-container.ms-Callout {
  border: 0 none;
  box-shadow: initial;
  color: $midnight_blue;

  .ms-Callout-main {
    background-color: transparent;
  }

  .share-dropdown {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    a {
      font-size: 18px;
      background-color: white;
      height: 32px;
      width: 32px;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.alternate {
    background-color: transparent;
    color: white;

    .share-dropdown a {
      background-color: $midnight_blue;
    }
  }
}

.video-home {
  // border-top: 1px solid $gray-100;
  // padding-top: 80px;
  margin-top: 80px;

  h2 {
    margin-bottom: 20px;
  }

  iframe {
    height: 200px;

    @include media-breakpoint-up(md) {
      height: 400px;
    }
    @include media-breakpoint-up(lg) {
      height: 640px;
    }
  }
}

.etp-hero {

  &.blue {
    background-color: $blue;
  }

  &.black {
    background-color: $black;
  }

  &.green {
    background-color: $green;
    .richtext-title {
      color: $black;
    }
  }

  &.blue,
  &.black {
    .richtext-title {
      color: $white;
    }
  }

  .etp-button-large {
    margin-top: 100px;
  }

  .etp-headlines {
    margin: 40px 0;

    .etp-headline {
      line-height: 100%;
      p {
        margin: 20px 0;
      }
    }
  }

  .etp-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.08em;
    text-align: left;
    text-transform: uppercase;
    margin: 0;
  }

  .etp-quote-wrapper {
    margin: 60px 0;
    row-gap: 0;
  }

  &.green {
    .etp-headline {
      color: $black;
    }
    .etp-quote {
      border-left: 1px solid $black;
      p {
        color: $black;
      }
    }
    .button {
      background-color: $black;
      span {
        color: $white;
      }
      .icon-foward {
        filter: invert(1);
      }
      &::before {
        background: $green;
      }
      &:after {
        background-color: $black;
      }
    }
  }
}


@include media-breakpoint-up(xs) {
  .etp-hero {
    padding-top: 135px;
    padding-bottom: 30px;
  }
}

@include media-breakpoint-up(sm) {
  .etp-hero {
    padding-top: 220px;
    padding-bottom: 120px;
  }
}

@include media-breakpoint-up(md) {
  .etp-hero {
    padding-top: 220px;
    padding-bottom: 120px;

    .etp-quote-wrapper {
      margin: 100px 0;
    }
  }
}