@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;


.headline-container,
.headline-container + .quote-container {
	--bg: #{$black};
	background-color: var(--bg);
}
.theme-green {
	.headline-container,
	.headline-container + .quote-container {
		--bg: #{$green};
		color: $black;
	}
}
.theme-blue {
	.headline-container,
	.headline-container + .quote-container {
		--bg: #{$blue};
	}
}
.headline-container + .quote-container {
	.container:first-child {
		padding-top: 0;
	}
}

@include media-breakpoint-up(xs) {
	.headline {
		padding-top: 135px;
		padding-bottom: 60px;
		gap: 40px;
		display: flex;
		flex-direction: column;

		.button {
			display: inline-flex;
			width: auto;
			align-self: flex-start;
			justify-self: left;
		}

		h1 + .sub-header {
			
			font-style: normal;
			font-weight: 300;
			font-size: 20px;
			line-height: 28px;
			margin-top: -15px;
		}

		.headline-actions {
				display: flex;
				padding-top: 20px;
				gap: 20px; 
				flex-direction: column;
		}
	}

	.breadcrumb {
		display: flex;
		text-decoration: none;
		gap: 12px;
		align-items: center;
		font-size: 10px;
		line-height: 12px;
		
		font-style: normal;
		font-weight: 600;
		letter-spacing: 0.1em;
		// text-transform: uppercase;
		margin-bottom: -20px;

        img {
            height: 18px;
			vertical-align: middle;
			transform: rotate(180deg);
        }

		svg{
			margin-right: 5px;
		}

		@include media-breakpoint-up(lg) {
			font-size: 12px;
			line-height: 12px;

            img {
                height: 18px;
				vertical-align: middle;
				transform: rotate(180deg);
            }
		}

		
	}

    .headline-container {
        & + .gray, & + .white, & + .light {
            margin-top: 60px;
        }
    }
}
@include media-breakpoint-up(sm) {
	.headline {
		padding-top: 220px;
		padding-bottom: 120px;
	}
    
    .headline-container {
        & + .gray, & + .white, & + .light {
            margin-top: 140px;
        }
    }
}

@include media-breakpoint-up(md) {
	.headline {
		.headline-actions {
			flex-direction: row;
		}
	}
	
}



.headline-icon {
    display: flex;
    align-items: flex-start;
    column-gap: 30px;

    img {
        width: 83px;
    }
}