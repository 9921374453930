@use '../../styles/vars' as *;

.etp-featured-statements {
  display: grid;
  grid-template-columns: repeat(auto-fill, 360px);
  justify-content: center;
  column-gap: 80px;
  row-gap: clamp(40px, 20vw, 120px);

  &.etp-featured-statements-stacked {
    display: flex;
    flex-direction: column;

    .etp-featured-statement {
      width: initial;
      flex: 0 0 auto;
    }
  }
}

.etp-featured-statement {
  width: 360px;
  flex: 0 0 360px;
  border-top: 1px solid $gray-800;
  padding-top: 50px;

  .etp-featured-statement-number {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 400;
    color: $gray-700
  }

  .etp-featured-statement-title {
    margin-top: 10px;
  }

  .etp-featured-statement-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: $gray-800;
  }
}