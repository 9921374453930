@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.product-alert-container {
	// @include widget;

	// padding-top: 80px;

	.product-alert {
		background-color: $black;
		border: 1px solid $white;

		a {
			background-color: transparent;
			text-decoration: underline;
		}

		.product-alert-detail {
			display: flex;
			flex-direction: column;
			row-gap: 10px;
			margin-top: 10px;

			@include media-breakpoint-up(lg) {
				flex-direction: row;
				justify-content: space-between;
				column-gap: 10px;
			}

			p {
				margin: 0px;
			}

			.svg-inline--fa {
				font-size: 1.3em;
			}
		}

		@include media-breakpoint-up(xs) {
			padding: 16px;
		}

		@include media-breakpoint-up(lg) {
				padding: 15px 40px;
		}
	}

	.links {
		display: flex;
		gap: 30px;
        row-gap: 10px;
		flex-wrap: wrap;
	}
}

.quote-container + .product-alert-container {
	margin-top: 80px;
}