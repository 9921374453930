@use "../../styles/mixins" as *;
@use "../../styles/vars" as *;

.etp-presentation {
  &__picture {
    width: 80px;
    height: 80px;
    border: 1px solid $black;
    padding: 15px 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .row {
    margin: clamp(40px, 10vw, 120px) 0;
  }

  .etp-presentation-numbers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 60px;

    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 60px;
    }
  }

  .etp-presentation-articles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 80px;
    column-gap: 40px;

    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }
  }
}
