@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.etp-asset-graph-data-title {
  font-size: 24px;
  line-height: 1;
  margin-bottom: clamp(40px, 10vw, 110px);

  @include media-breakpoint-up(lg) {
    font-size: 48px;
  }
}

.etp-asset-graph-donut {
  display: none;

  @include media-breakpoint-up(lg) {
    display: initial;
  }
}

.etp-asset-graph-data {
  display: flex;
  flex-direction: column;
  column-gap: 40px;
  max-height: 400px;
  flex-wrap: wrap;

  .etp-asset-graph-data-row {
    flex: 0 1 65px;
    min-height: 50px;
    border-top: 1px solid $gray-900;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    transition: all 0.3s linear;

    &.fade-out {
      opacity: 0.3;

      img {
        filter: grayscale(1);
      }
    }

    .etp-asset-graph-data-name {
      font-weight: bold;
      display: flex;
      align-items: center;
      column-gap: 6px;
    }

    .etp-asset-graph-data-weight {
      display: flex;
      column-gap: 5px;
    }
  }
}

.etp-donut-container {
  position: relative;

  .etp-donut-updated {
    bottom: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    color: $gray-600;
    font-size: 12px;
    text-transform: uppercase;
  }

  .etp-donut-details {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 12px;

    .etp-donut-details-header {
      display: flex;
      align-items: center;
      margin-bottom: 28px;
      column-gap: 8px;
    }

    .etp-donut-details-row {
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      > span:first-of-type {
        font-weight: bold;
      }
    }
  }
}

.etp-asset-graph-statements {
  margin-top: clamp(40px, 10vw, 110px);;
}