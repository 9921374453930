@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.white,
.gray
{

  .etp-button {
    border-color: $black;
    color: $black;

    &.etp-button-solid {
      background-color: $black;
      color: $white;
    }

    .etp-button-text {
      border-right-color: $black;
    }
  }
}

.black,
.blue {
  .etp-button {
    border-color: $white;
    color: $white;

    &.etp-button-solid {
      background-color: $white;
      color: $black;
    }

    .etp-button-text {
      border-color: $white;
    }
  }
}

.etp-button {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  padding: 0;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  .etp-button-text {
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.01em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 15px;
    font-size: 11px;
    border-right-width: 1px;
    border-right-style: solid;
  }

  .etp-button-chevron {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
  }
}

@include media-breakpoint-up(md) {
  .etp-button-large {
    .etp-button-text {
      padding: 25px 28px;
      font-size: 14px;
    }

    .etp-button-chevron {
      padding: 0 28px;
    }
  }
}