@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.etp-footer {
  padding: 80px 0;

  .row + .row {
    margin-top: clamp(40px, 18vw, 140px);
  }

  .etp-footer-logo {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 24px;
    }
  }

  .etp-footer-socials {
    gap: 24px;
  }

  .etp-footer-options {
    @include media-breakpoint-up(lg) {
      padding-right: 90px;
    }
  }

  .etp-footer-option {

    > div {
      margin-top: 8px;
    }
  }

  .etp-footer-option + .etp-footer-option {
    margin-top: 24px;
  }

  .etp-footer-option-label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: $gray-600;
  }

  small {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $gray-600;
  }

  .fui-Select__icon {
    right: 1rem !important;
  }

  .fui-Select__select {
    padding: 1rem !important;
    height: auto !important;
    width: 100%;
  }


  .etp-footer-link-column {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  .etp-footer-link-column + .etp-footer-link-column {
    margin-top: 50px;
  }

  .etp-footer-info-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
  }

  .etp-footer-dropdown-chevron {
    vertical-align: middle;
  }

  .etp-footer-link {
    cursor: pointer;
    text-decoration: none;
    font-weight: 400;
    font-size: 1rem;
    line-height: 25px;
    color: #FFFFFF;
  }
}

