@use '../../styles/mixins' as *;

.etp-pill-options {
  flex: 0;

  .etp-pill-options-buttons {
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    display: none;
    justify-content: flex-end;
  }

  @include media-breakpoint-up(lg) {
    flex: 1;

    .etp-pill-options-buttons {
      display: flex;
    }

    .ms-Dropdown-container {
      display: none;
    }
  }
}
