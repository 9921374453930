@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.staking-rewards-container {
	display: grid;
	grid-template:
		'staking-info'
		'staking-divider'
		'staking-rewards';
	padding-top: 60px;

	.staking-information {
		grid-area: staking-info;
	}

	.staking-rewards {
		grid-area: staking-rewards;
		display: flex;
		flex-direction: column;
		row-gap: 30px;
	}

	@include media-breakpoint-up(lg) {
		display: grid;
		grid-template: 'staking-info staking-divider staking-rewards';
		column-gap: 40px;

		.staking-divider {
			content: '';
			width: 1px;
			height: 100%;
			background-color: rgba($dark_blue, 0.25);
		}

		.staking-rewards {
			row-gap: 40px;
		}
	}
}

.link {
	
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	display: inline-flex;
	column-gap: 10px;
    text-decoration: none;
    align-items: center;
}
