@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.etp-offers-title {
  color: $gray-700;
}

.etp-offers-text {
  max-width: 720px;
}

.etp-offers-list {
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 40px;
  row-gap: 80px;
}

.etp-offers-footer {
  color: $gray-800;
  font-size: 14px;
}

.etp-offer {
  max-width: 550px;
  border-left: 1px solid $gray-300;
  padding-left: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 60px;

  .etp-offer-link {
    margin-top: auto;
  }
}

.etp-offer-list {
  margin: 0;
  padding-left: 20px;

  li {
    padding: 10px 0;
  }
}


@include media-breakpoint-up(md) {
  .etp-offers-list {
    padding: 80px 0;
  }
}
