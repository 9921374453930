@use "../../styles/_vars" as *;
@use "../../styles/mixins" as *;

.black,
.blue {
  .partner-and-leader {
    color: $white;

    .partner-logos {
      filter: brightness(0) invert(1);
    }
  }
}

.white,
.gray {
  .partner-and-leader {
    color: $black;

    .partner-logos {
      filter: brightness(0) invert(0.7);
    }
    .leader-list-text {
      color: $gray-700;
    }
  }
}

.partner-and-leader {
  padding: 80px 0px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  .leader-list {
    padding-top: 0;
    color: $gray-700;
    .leader-list-text {
      text-transform: uppercase;
      white-space: nowrap;
      font-weight: 500;
      font-size: 12px;
      margin-top: 6px;
    }
    .partner-logo {
      display: grid;
      grid-auto-rows: 30px;
      grid-template-columns: repeat(2, fit-content(50px));
      justify-content: flex-start;
      align-items: center;
      column-gap: 40px;
      row-gap: 40px;
      flex-wrap: wrap;
      filter: brightness(0) invert(0.7);

      > img {
        max-width: 130px;
        @include media-breakpoint-up(sm) {max-width: 240px;}
      }
      @include media-breakpoint-up(sm) {
        grid-auto-rows: 40px;
        grid-template-columns: repeat(3, fit-content(70px));
        justify-content: space-between;

      }
      @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(5, fit-content(70px));
      }
    }
  }
}
