@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.etp_disclaimer_small-title {
  .col-md-4.col-md-4 {
    width: auto;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .title {
    font-size: 20px;
    line-height: 1.5;

    @include media-breakpoint-up(xs) {
      font-size: 18px;
    }
  }

  .col-md-8 {
    width: auto;

    @include media-breakpoint-up(xxl) {
      max-width: 870px;
      padding-right: 0 !important;
      padding-left: 24px !important;
    }


    max-width: 100%;
    padding-left: 0 !important;
  }

  ul {
    margin-top: 0 !important;

    .mb-2 {
      margin-bottom: 0 !important;
    }
  }


  li {
    font-size: 14px;
  }
}