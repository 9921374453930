@use "../../styles/mixins" as *;
@use "../../styles/vars" as *;

.richtext-with-video {
    display: grid;
    grid-template-columns: 100%;
    @include media-breakpoint-up(md) {
        grid-template-columns: 48% 48%;
        grid-column-gap: 4%;
    }
    &__right {
        display: flex;
        align-items: center;
    }
    .video-container {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
    }
    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.no-video {
    grid-template-columns: 100%;
}
