@use '../../styles/vars' as *;

.etp-reserve-details {

  .etp-reserve-details-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
  }

  .etp-reserve-details-logos {
    background-color: $gray-100;
    padding: 60px 0;
    min-height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;

    > img {
      max-width: 80%;
    }
  }
}