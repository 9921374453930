@use '../../styles/vars' as *;

.etp-proof-of-reserves-title {
  margin-bottom: 100px;
}

.etp-proof-of-reserves-logo {
  background-color: $gray-0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;
  height: 100%;

  img {
    max-width: 100%;
  }
}