@use '../../styles/vars' as *;

.weight-indicator {
	display: inline-flex;
	align-items: center;

	.indicator-positive,
	.indicator-negative,
	.indicator-neutral {
		border-left: solid 5px transparent;
		border-right: solid 5px transparent;
		height: 0;
		width: 0;
	}

	.indicator-positive {
		border-bottom: solid 8px $midnight_blue;
	}

	.indicator-negative {
		border-top: solid 8px $midnight_blue;
	}

	.indicator-neutral {
		height: 3px;
		width: 5px;
		background-color: $midnight_blue;
		border: 0;
		margin-right: 5px;
	}
}

.asset-breakdown-table {
	border-collapse: collapse;
	thead tr th,
	tbody tr td {
		padding: 0 15px 15px 15px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		border-top: 0px none;
		vertical-align: top;
	}

	tbody tr td {
		padding-top: 15px;
		transition: ease 0.3s color;

		.circle-legend {
			min-width: 17px;
			height: 17px;
			border-radius: 50%;
			display: inline-block;
		}

		.asset {
			transition: ease 0.3s text-decoration, ease 0.3s color;
			text-decoration: underline black dotted;
			text-underline-offset: 5px;
		}

		.weight-indicator {
			display: flex;
			justify-content: space-between;
			.indicator-positive,
			.indicator-negative,
			.indicator-neutral {
				transition: ease 0.3s border-color, ease 0.3s background-color;
				border-left: solid 10px transparent;
				border-right: solid 10px transparent;
			}

			.indicator-positive {
				border-bottom: solid 15px $midnight_blue;
			}

			.indicator-negative {
				border-top: solid 15px $midnight_blue;
			}

			.indicator-neutral {
				height: 3px;
				width: 10px;
				background-color: $midnight_blue;
				border: 0;
				margin-right: 5px;
			}
		}
	}

	thead tr,
	tbody tr {
		background-color: transparent !important;
		transition: ease 0.3s background-color, ease 0.3s color;
	}

	tbody tr {
		cursor: pointer;

		&:hover,
		&.selected {
			color: white;
			background-color: $midnight_blue !important;

			.asset {
				color: white;
				text-decoration: underline white dotted;
			}

			.weight-indicator {
				.indicator-positive {
					border-bottom-color: white;
				}

				.indicator-negative {
					border-top-color: white;
				}

				.indicator-neutral {
					background-color: white;
				}
			}
		}
	}
}

.donut-graph-container {
	position: relative;
	padding-bottom: 100%;

	.donut-graph {
		position: absolute;
	}

	.donut-graph-header {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 140px;

		text-align: center;

		display: flex;
		flex-direction: column;
		row-gap: 5px;

		.title {
			font-family: 'alliance_no_1';
			font-style: normal;
			font-weight: 700;
			font-size: 30px;
			line-height: 25px;
		}
	}
}
