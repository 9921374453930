@use '../../styles/mixins' as *;

.etp-video {

  .etp-video-row {
    margin-bottom: 160px;

    .etp-video-wrapper {
      position: relative;
      padding-top: 56.25%;
    }

    .etp-image-wrapper {
      img {
        inline-size: 100%;
      }
    }

    .etp-video-link {
      margin-top: 40px;
    }


    iframe {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}