@use '../../styles/vars' as *;

.etp-reserves-explanation-logo {
  padding: 55px;
  min-height: 300px;
  height: 100%;
  background-color: $gray-0;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    flex: 1 1 100px;
    max-width: 300px;
  }
}

.etp-reserves-explanation-title {
  margin-bottom: clamp(30px, 10vw, 100px);
}

.etp-reserves-explanation-text {
  margin-top: clamp(20px, 10vw, 40px);
  font-size: 14px;
}