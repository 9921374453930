@use './vars' as *;

:root {
  --bg: #{$white};
}

.light,
.white {
  color: $black;
  background-color: var(--bg);
}

.gray {
  --bg: #{$gray-0};
  color: $black;
  background-color: var(--bg);;
}

.blue,
.black,
.transparent {
  --bg: #{$black};
  background-color: var(--bg);

  .label {
    color: $gray-700;
  }
}

.bl-blue {
  border-left: 1px solid $midnight_blue;
}

.bb-gray {
  border-bottom: 1px solid $gray-950;
}

.hidden {
  width: 0 !important;
  height: 0 !important;
  opacity: 0 !important;
}

.ft-center {
  text-align: center;
}