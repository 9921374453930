@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.tab-strip {
	@include widget;

	.center-header {
		display: flex;
		align-items: center;
		flex-direction: column;
		row-gap: 40px;
		> * {
			text-align: center;
			width: 55%;
		}
		p {
			margin: 0px;
		}
	}

    &.inline {
        padding: 0;
    }

	.tabs {
		// margin-top: 80px;
		display: flex;
		flex-direction: column;
		row-gap: 20px;

		@include media-breakpoint-up(lg) {
			border: 1px solid $gray-950;

			.tab-content {
					border: 0 none;
			}
		}

		.tab-headers {
			display: flex;
			flex-direction: column;

			gap: 2px;
			.tab {
				display: none;
				cursor: pointer;
				text-align: center;
				text-transform: uppercase;

				background-color: $gray-0;
				justify-content: center;
				align-items: center;
				color: $gray-600;

				flex: 1;
				padding: 20px;

				h5 {
					font-family: 'alliance_no_2';
					font-weight: 600;
					font-size: 14px;
					line-height: 20px;
					letter-spacing: 0.08em;
					text-transform: capitalize;
				}

				&.selected {
					background-color: transparent;
					color: $black;
					h5 {
						// color: $midnight_blue;
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			row-gap: 0;
			.tab-headers {
				flex-direction: row;
				.tab {
					display: flex;
					flex: 1;
				}

				.tab-dropdown {
					display: none;
				}
			}
		}

		.tab-content {
			// padding: 40px;
			min-height: 100px;
			// background-color: white;
			@include media-breakpoint-up(lg) {
				padding: 40px;
			}

			.tabs {
				margin-top: 0px;

				.tab-headers {
					justify-content: left;
					flex-direction: row;
					flex-wrap: wrap;
					.tab {
						display: inline-flex;
						flex: 0;
						white-space: nowrap;
						background: none;
						border-radius: 64px;
						border: 1px solid $gray-400;
						color: $gray-700;
						padding: 8px 24px;
						margin-right: 10px;


						&.selected {
							border-color: $black;
							color: $black;
						}
					}

					.tab-dropdown {
						display: none;
					}
				}

				.tab-content {
					// border-top: 1px solid $gray;
					padding-left: 0px;
					padding-right: 0px;
				}
			}
		}
	}

	&.horizontal {
		padding: 0px;
		.tabs {
			margin-top: 0px;
			.tab-headers {
				.tab-dropdown {
					max-width: 250px;
				}
			}

			.tab-content {
				flex: 1;
			}

			@include media-breakpoint-up(lg) {
				flex-direction: row;
				.tab-headers {
					flex-direction: column;
					row-gap: 2px;
					min-width: 250px;

					.tab {
						display: initial;
						flex: initial;
					}
				}
			}
		}
	}
}

.article-container {
    .tab-strip {
        .tabs {
            border: 1px solid $midnight_blue
        }
    }
}

.transparent,
.blue {
	.tab-strip {
		.tabs {
			// background-color: rgba($midnight_blue, 0.8);
			
			.tab {
				background-color: $gray-950;
				color: $gray-600;

				&.selected {
					background-color: transparent;
					color: $white;
				}
			}

			// .tab-content {
			// 	// background-color: rgba($midnight_blue, 0.8);
      //           border: 1px solid white;
			// }

			@include media-breakpoint-up(lg) {
				border: 1px solid white;

				// .tab-content {
				// 		border: 0 none;
				// }
			}
		}
	}
}

// .gray .tab-strip .tab-content {
// 	.button {
// 		background-color: white;

// 		&:not(.link-button):hover {
// 			background-color: $midnight_blue;
// 			color: white;
// 		}
// 	}
// }
.gray {
	.tab-strip .tabs {
		

		@include media-breakpoint-up(lg) {
			border-color: $gray-200;
			background: $white;
		}

		.tabs {
			background: none;
			border: none;
		}
	}
}