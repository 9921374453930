.etp-reserve-widgets {
  > .row:first-of-type {
    margin-bottom: clamp(60px, 20vw, 180px);
  }
}

.etp-reserve-widgets-widget {
  display: flex;
  justify-content: center;

  > iframe {
    width: 500px;
    height: 616px;
    border: none;
    border-radius: 10px;
  }
}

.etp-reserve-widgets-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  > img {
    height: 24px;
    margin-bottom: 40px;
  }

  > p {
    margin-bottom: clamp(40px, 5vw, 80px);
  }

  > button {
    margin-bottom: 20px;
  }
}