@use '../../styles/mixins' as *;

.etp-banner {
  height: 5rem;
  width: 100%;
  background: #CFFF24;
  overflow: hidden;
}

.etp-banner-text {
  font-family: 'alliance_no_2', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 20px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #000000;
  text-decoration: none;
  white-space: nowrap;
  margin-left: 2.5rem;
}
