@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.etp-btc-market-cap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px;

  @include media-breakpoint-up(md) {
    justify-content: space-between;
  }

  @include media-breakpoint-up(lg) {
    padding-right: 60px;
  }
}