@use '../../styles/vars' as *;

.etp-security-logo {
  background-color: $gray-100;
  padding: 60px 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.etp-security-text {
  h3 {
    margin: 0 0 40px 0;
  }
  p {
    margin: 0;

    &:first-of-type {
      font-size: 18px;
      margin-bottom: 20px;
    }

    &:nth-of-type(2) {
      font-size: 14px;
    }
  }
}