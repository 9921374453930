@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.etp-document {
  display: grid;
  align-items: center;
  justify-items: space-between;
  column-gap: 15px;
  padding: 20px 0;
  grid-template-areas:
    "icon name download"
    "icon type download";

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;

  .document-icon {
    grid-area: icon;
  }

  .document-name {
    grid-area: name;
  }

  .document-type {
    grid-area: type;
  }

  .link {
    grid-area: download;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
    justify-self: flex-end;

    span {
      display: none;
    }
  }

  @include media-breakpoint-up(md) {
    column-gap: 30px;
    grid-template-areas: "icon name type download";
    grid-template-columns: 50px 4fr 2fr 3fr;

    .link {
      span {
        display: initial;
      }
    }
  }
}

.etp-document:not(:last-child) {
  border-bottom: 1px solid rgba($dark_blue, 0.25);
}
