@use './vars' as *;
@use './mixins' as *;
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// @font-face {
// 	font-family: 'alliance_no_2';
// 	src: url('../fonts/klavika-bold.otf') format('opentype');
// 	font-weight: 700;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'alliance_no_2';
// 	src: url('../fonts/klavika-medium.otf') format('opentype');
// 	font-weight: 500;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'alliance_no_2';
// 	src: url('../fonts/klavika-light.otf') format('opentype');
// 	font-weight: 300;
// 	font-style: normal;
// }


@font-face {
	font-family: 'alliance_no_1';
	src: url('../fonts/allianceno.1-semibold-webfont.woff2') format('woff2'),
			 url('../fonts/allianceno.1-semibold-webfont.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'alliance_no_1';
	src: url('../fonts/allianceno.1-regular-webfont.woff2') format('woff2'),
			 url('../fonts/allianceno.1-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'alliance_no_1';
	src: url('../fonts/allianceno.1-bold-webfont.woff2') format('woff2'),
			 url('../fonts/allianceno.1-bold-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'alliance_no_1';
	src: url('../fonts/allianceno.1-medium-webfont.woff2') format('woff2'),
			 url('../fonts/allianceno.1-medium-webfont.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'alliance_no_2';
	src: url('../fonts/allianceno.2-semibold-webfont.woff2') format('woff2'),
			 url('../fonts/allianceno.2-semibold-webfont.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'alliance_no_2';
	src: url('../fonts/allianceno.2-regular-webfont.woff2') format('woff2'),
			 url('../fonts/allianceno.2-regular-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'alliance_no_2';
	src: url('../fonts/allianceno.2-medium-webfont.woff2') format('woff2'),
			 url('../fonts/allianceno.2-medium-webfont.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'alliance_no_2';
	src: url('../fonts/allianceno.2-bold-webfont.woff2') format('woff2'),
			 url('../fonts/allianceno.2-bold-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5 {
	font-family: 'alliance_no_2';
	font-style: normal;
	font-weight: 400;
	margin: 0;
}

// .light,
// .white,
// .gray {
// 	h1,
// 	h2,
// 	h3,
// 	h4,
// 	h5 {
// 		color: $midnight_blue;
// 	}
// }

@include media-breakpoint-up(xs) {
	h1, .h1 {
		font-size: 48px;
		line-height: 60px;

		&.article {
			font-size: 40px;
			line-height: 50px;
		}
	}

	h2, .h2 {
		font-weight: 400;
		font-size: 32px;
		line-height: 40px;
	}

	h3, .h3 {
		font-weight: 400;
		font-size: 23px;
		line-height: 30px;
	}

	h4, .h4 {
		font-size: 20px;
		line-height: 30px;

		&.article {
			font-weight: 300;
			letter-spacing: 0.02em;
		}
	}

	h5, .h5 {
		font-size: 18px;
		line-height: 25px;
	}
}

@include media-breakpoint-up(md) {
	h1, .h1 {
		font-size: 76px;
    line-height: 87px;
	}

	h2, .h2 {
		font-weight: 300;
		font-size: 42px;
		line-height: 50px;
	}

	h3, .h3 {
		font-size: 36px;
		line-height: 43px;
		letter-spacing: 0.03em;
	}

	h4, .h4 {
		font-size: 24px;
		line-height: 29px;

		&.article {
			font-size: 24px;
			line-height: 28px;
			text-transform: none;
		}
	}

    h5, .h5 {
      font-size: 20px;
			line-height: 30px;
        &.content {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

@include media-breakpoint-up(xl) {
    // .roboto-light-intro {
    //     font-size: 20px;
    //     line-height: 28px;
    // }
		h1, .h1 {
			&.article {
				font-size: 88px;
				line-height: 100px;
			}
		}
}

@include media-breakpoint-up(xxl) {
	h1, .h1 {
		font-size: 104px;
		line-height: 120px;
	}

    h2, .h2 {
        font-size: 48px;
        line-height: 60px;
    }

    h3, .h3 {
        font-size: 32px;
        line-height: 40px;
    }

    h4, .h4 {
        font-size: 26px;
        line-height: 32px;
    }
}


.page-content {
	@include klavika-light;
}

.body-intro {
	@include roboto-light-intro;
}

.uppercase {
	text-transform: uppercase;
}

.label {
	font-family: 'alliance_no_2';
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.c-gray-700 { color: $gray-700; }
.c-gray-900 { color: $gray-900; }