@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.active_asset_banner {
  background-color: #000;
  position: fixed;
  top: 0px;
  width: 100%;
  left: 0px;
  display: block;
  z-index: 2;
  padding: 10px 0px;
  .active_asset_banner-row {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 5px;
    p {
      padding: 0px;
      margin: 0px;
      font-size: 14px;
      font-weight: 400;
    }
    span {
      font-size: 14px;
      padding: 0px;
      margin: 0px;
    }
    .hedge-fund-solutions-button {
      text-decoration: underline;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}
