@use '../../styles/vars' as *;

.button {
	position: relative;
	
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	align-items: center;

	text-align: center;
	display: inline-flex;
	gap: 4px;

	color: $black;
	background-color: $white;
	justify-content: center;

	text-decoration: none;
	border: none;
	border-radius: 0;
	padding: 10px 12px 10px 20px;

	&.no-icon {
		padding: 10px 20px;
	}
	cursor: pointer;

    &:disabled {
        opacity: 0.5;
    }

	&:before,
	&:after {
		content: '';
		position: absolute;
		bottom: -8px;
		left: -8px;
		width: 8px;
		height: 8px;
		background-color: $white;
		border-radius: 1px;
		transition: transform .1s ease-in;
	}
	&:before {
		background: var(--bg);
		bottom: 0;
		left: 0;
		border-radius: 0 1px 0 0;
	}
	// &:after {
	// 	transition: transform .1s ease-in;
	// }

	transition: background-color 0.3s linear, color 0.3s linear;

	img {
		height: 20px;
	}
	svg {
		fill: currentColor;
	}

	&:not(.link-button):hover {
		background-color: white;
		color: $midnight_blue;

		&:after {
			transform: translate(8px, -8px);
		}
		&:before {
			transform: translate(-8px, 8px);
		}

		.icon-foward {
			transform: translate(4px, 0);
		}
	}

	&.secondary {
		border: 1px solid $white;
		background: none;
		color: $white;
		// padding: 9px 20px;
		padding-top: 9px;
		padding-bottom: 9px;
	}

	&.link-button {
		border: 0 none;
		padding: 0px;
		gap: 10px;
		color: $white;
		background: none;

		span {
			white-space: nowrap;
		}
	}

	.icon-foward {
		transition: .1s transform ease-in;
	}
}

.button.secondary,
.button--tag,
.link-button {
	&:before,
	&:after {
		display: none;
	}
}

.button--tag {
	border-radius: 64px;
	border: 1px solid $gray-800;
	background: none;
	color: $gray-700;
	font-weight: 400;
	padding: 10px 20px;


	&.is-active {
		border-color: $white;
		color: $white;
	}
}

.icon-button {
    background: transparent;
    border: 0 none;
    color: white;
    cursor: pointer;
}

.light,
.gray,
.white,
.theme-green .headline-container,
.theme-blue .headline-container,
.theme-green .headline-container + .quote-container,
.theme-blue .headline-container + .quote-container {
	.button {
		border-color: transparent;
		color: $white;
		background-color: $black;

		&:after {
			background-color: $black;
		}

		&.gray {
			border-color: rgba($dark_blue, 0.25);
		}

		&:not(.link-button):hover {
			background-color: $black;
			color: white;
		}

		.button-icon-dark {
				display: none;
		}

		.button-icon-light {
				display: initial;
		}

		&:not(.link-button):hover {
				.button-icon-dark {
						display: initial;
				}

				.button-icon-light {
						display: none;
				}
		}

		&.link-button {
			background: none;
			color: $white;

			.button-icon-dark {
				display: initial;
			}

			.button-icon-light {
					display: none;
			}
		}

		&.secondary {
			border: 1px solid $white;
			background: none;
		}
		.icon-foward {
			filter: invert(1);
		}
	}

	.button.primary {
		background-color: $midnight_blue;
		color: white;
	}


	.button--tag {
		background: none;
		border-color: $gray-400;
		color: $gray-700;
		padding: 10px 20px;

		&:not(.link-button):hover {
			background: $black;
		}

		&.is-active {
			border-color: $black;
			color: $black;
		}
	}
	
}

.light,
.gray,
.white,
.theme-green .headline-container,
.theme-green .headline-container + .quote-container {
	.button {
		&.secondary {
			border: 1px solid $black;
			color: $black;
			background: none;
		}
	}
}

.transparent {
	.button.primary {
		background-color: white;
		color: $midnight_blue;
	}

    .button {
        .button-icon-dark {
            display: initial;
        }
    
        .button-icon-light {
            display: none;
        }
    
        &:not(.link-button):hover {
            .button-icon-dark {
                display: none;
            }
    
            .button-icon-light {
                display: initial;
            }
        }
    }

	
}

// .gray {
// 	.button {
// 		background-color: $gray;
// 	}
// }


.button-strip {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid rgba($dark_blue, 0.25);
    flex-wrap: wrap;
}