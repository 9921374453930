.volume-container {
	position: relative;
	justify-content: center;
	.volume {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding-top: 80px;
		padding-bottom: 80px;
        opacity: 1;
		align-items: center;

        // &.animate-ease-left {
        //     opacity: 1;
        // }

		.volume-title {
			
			font-style: normal;
			font-weight: 600;
			font-size: 15px;
			line-height: 20px;
			letter-spacing: 0.03em;
			text-transform: uppercase;
		}
	}
}
