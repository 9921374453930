@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.etp-filters {
	display: flex;
	justify-content: space-between;

	.ms-Dropdown-label { display: none!important; }

	.etp-filter {
		gap: 20px;
		align-items: flex-end;
	}

	@include media-breakpoint-up(xs) {
		.etp-filter {
			width: 100%;
			.ms-Dropdown-container {
				width: 100%;
			}
		}
		.etp-filter:not(:first-child) {
			display: none;
		}
	}

	@include media-breakpoint-up(lg) {
		.etp-filter {
			width: initial;
			&:not(:first-child) {
				display: flex;
			}
		}
	}
}

.etps {

	display: grid;
	grid-template-columns: 1fr;
	row-gap: 20px;
	@include media-breakpoint-up(lg) {
		row-gap: 0px;
		border: 1px solid $gray-900;
	}
	column-gap: 0px;
	max-width: 100%;
	margin-top: 40px;

	.etp {
		display: grid;
		cursor: pointer;
		// background-color: white;
		// color: $midnight_blue;

		text-decoration: none;
		color: $white;
		padding: 30px;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		border: 1px solid $gray-900;
		&:first-child {
			display: none;
		}

		&:hover {
			background: $gray-950;
		}

		@include media-breakpoint-up(lg) {
			&:first-child {
				display: grid;
			}
			padding: 0px;
			grid-template-columns: 0.5fr 1.3fr 0.7fr 1fr 1fr 1.2fr 1.2fr;
			border: 0;
			border-bottom: 1px solid $gray-900;

		}
	}
}

.document-link {
	display: none;
	visibility: hidden;

	&.white {
		--bg: #{$white};
		background: $white;
	}

	@include media-breakpoint-up(lg) {
		display: flex;
		visibility: visible;
		height: 100px;
		text-decoration: none;
		justify-content: space-between;
		align-items: center;
		padding: 24px;
		border: 1px solid $white;
		font-size: 16px;
		line-height: 25px;
	}
}

.white {
	.document-link {
		border-color: $midnight_blue;
	}
}

.etp- {
	&logo,
	&name,
	&fee,
	&bloomberg,
	&isin,
	&currencies,
	&factsheet {
		display: flex;
		align-items: center;
		
		font-size: 14px;
		line-height: 20px;

		&:nth-child(n + 3) {
			border-top: 1px solid $gray-900;

			padding-top: 20px;
			padding-bottom: 20px;
		}

		@include media-breakpoint-up(lg) {
			height: 100px;
			padding: 0px;
			font-size: 16px;
			line-height: 19px;

			&:nth-child(n + 3) {
				border-top: 0 none;

				padding-top: 0px;
				padding-bottom: 0px;
			}
		}

		&.grid-header {
			display: none;

			@include media-breakpoint-up(lg) {
				display: flex;
				cursor: default;
				height: 80px;
				
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 20px;
				letter-spacing: 0.02em;
				font-family: 'alliance_no_2';
				color: $gray-500;
			}
		}

		a {
			text-decoration: none;
		}
	}

	&logo {
		justify-content: flex-start;
		margin-bottom: 25px;

		@include media-breakpoint-up(lg) {
			margin-bottom: 0px;
			justify-content: center;
		}
		img {
			height: 40px;

			// @include media-breakpoint-up(lg) {
			// 	height: initial;
			// }
		}
	}

	&name {
		// font-family: 'alliance_no_2';
		/* or 120% */

		display: flex;
		align-items: center;

		margin-bottom: 25px;

		h5 {
			font-weight: 600;
			font-size: 16px;
			line-height: 25px;
		}

		@include media-breakpoint-up(lg) {
			margin-bottom: 0px;
		}
	}

	&fee,
	&bloomberg,
	&isin,
	&currencies,
	&factsheet {
		display: flex;
		justify-content: space-between;
		&:before {
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
		}
	}

	&fee {
		&:before {
			content: 'Fee';
		}
	}

	&bloomberg {
		&:before {
			content: 'Bloomberg';
		}
	}

	&isin {
		&:before {
			content: 'ISN';
		}
	}

	&currencies {
		&:before {
			content: 'Trading currencies';
		}
	}

	&factsheet {
		font-weight: 400;
		&:before {
			content: 'Factsheet';
		}

		a {
			display: flex;
			gap: 10px;
		}
	}

	@include media-breakpoint-up(lg) {
		&fee,
		&bloomberg,
		&isin,
		&currencies,
		&factsheet {
			&:before {
				content: initial;
			}
		}
	}
}

// .etp {
//     background-color: white;
//     color: $midnight_blue;
//     height: 100px;
//     padding-left: 30px;
//     padding-right: 30px;

//     a {
//         text-decoration: underline;
//     }
// }

.etp-document-list-container {
	& + .etp-document-list-container {
		margin-top: 60px;
	}

	.etp-document-list {
		margin-top: 10px;
		border-top: 1px solid $midnight_blue;

		.etp-document {
			display: grid;
			align-items: center;
			justify-items: space-between;
			column-gap: 15px;
			padding: 20px 0;
            grid-template-areas: 'icon name download'
                                    'icon type download';

			
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			text-transform: uppercase;

            .document-icon {
                grid-area: icon
            }

            .document-name {
                grid-area: name
            }

            .document-type {
                grid-area: type
            }


			.link {
                grid-area: download;
				
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				text-decoration: none;
				justify-self: flex-end;

                span {
                    display: none;
                }
			}

			@include media-breakpoint-up(md) {
                column-gap: 30px;
                grid-template-areas: 'icon name type download';
				grid-template-columns: 50px 4fr 2fr 3fr;

                .link {
                    span {
                        display: initial;
                    }
                }
			}
		}

		.etp-document:not(:last-child) {
			border-bottom: 1px solid rgba($dark_blue, 0.25);
		}
	}
}

.etp-document-filters {
    display: none;

    @include media-breakpoint-up(lg) {
        display: flex;
        column-gap: 10px;
        justify-content: flex-end;
        padding-bottom: 30px;    
    }
    
}
