@use '../../styles/mixins' as *;

.etp-leading-the-way-facts {
  padding-top: clamp(40px, 20vw, 100px);
  display: flex;
  flex-direction: column;
  column-gap: clamp(20px, 5vw, 80px);
  row-gap: 30px;

  .etp-leading-the-way-fact {
    flex: 0 0 auto;
    padding-top: 20px;

     p {
       font-size: 14px;
     }
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;

    .etp-leading-the-way-fact {
      flex: 1 1 300px;
      padding-top: clamp(20px, 5vw, 80px);
    }
  }
}