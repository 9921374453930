@use '../../styles/vars' as *;

.etp-company-square {
  height: 120px;
  width: 120px;
  flex: 0 0 140px;
  padding: 12px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid $gray-0;
  cursor: pointer;
  transition: background-color 150ms ease-in;

  &:hover {
    background-color: $gray-0;

    .etp-company-square-link-icon {
      display: initial;
    }
  }

  img {
    max-height: 60%;
    max-width: 100%;
  }

  .etp-company-square-link-icon {
    display: none;
    position: absolute;
    top: 8px;
    right: 7px;
    color: $gray-400;
  }
}

.etp-company-square-link-icon {
  position: absolute;
  top: 8px;
  right: 7px;
}