.etp-brokers {
  .ept-logo-squares {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .etp-exchanges-list {
    margin-top: clamp(20px, 20vw, 180px);
  }

  .etp-brokers-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  .etp-logo-list {
    .logo-list {
      padding-top: 0px;
    }
  }
}
