@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.popup-notice {
  max-width: 480px;
  border: 1px solid white;
  color: white;
  padding: 32px;
  margin: 10px;

  .ms-Label {
    font-family: 'alliance_no_2', sans-serif;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 0.75rem;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    &::after {
      content: " *";
      padding-right: 12px;
      font-family: 'alliance_no_2', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }

  p {
    @include roboto-light-intro;
    margin: 0;
  }

  .ms-Modal-scrollableContent {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    padding-bottom: 10px;
  }

  .notice-disclaimer {
    font-family: 'alliance_no_1', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .notice {
    color: $light_blue;
    padding: 30px 40px;
    border: 1px solid $light_blue;

    @include media-breakpoint-up(lg) {
      padding: 30px 60px;
    }
  }

  .missing-notice {
    font-family: 'alliance_no_1', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FF243E;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-header {
    font-family: 'alliance_no_2', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
  }

  .modal-description {
    font-family: 'alliance_no_1', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }

  .modal-radio-group {
    --strokeWidthThin: 1px;
    --borderRadiusCircular: 50%;
    --spacingVerticalS: 2px;
    --spacingHorizontalS: 0.75rem;
  }

  .modal-radio-label {
    font-family: 'alliance_no_2', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .modal-radio {
    font-family: 'alliance_no_1', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 25px;
    color: #FFFFFF;
    --colorNeutralStrokeAccessible: #3F3F3F
  }
  .error {
    position: absolute;
    color: $light_blue;
    
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
      padding-top: 10px;
      margin: 0px !important;
  }
}