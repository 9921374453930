@use '../../styles/vars' as *;

.etp-article-link {
  flex: 1 1 300px;
  min-width: 260px;
  padding-top: 40px;
  border-top: 1px solid $gray-500;

  h4 {
    margin-bottom: 20px;
  }
}

