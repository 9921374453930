@use './vars' as *;

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin widget {
    @include media-breakpoint-up(xs) {
		padding-top: 80px;
		padding-bottom: 70px;
	}

	@include media-breakpoint-up(sm) {
		padding-top: 160px;
		padding-bottom: 140px;
	}
}

@mixin klavika-light {
	@include media-breakpoint-up(xs) {
		font-family: 'alliance_no_2';
		font-style: normal;
		font-weight: 300;
		font-size: 22px;
		line-height: 26px;
		letter-spacing: 0.02em;
	}

	@include media-breakpoint-up(md) {
		font-size: 30px;
		line-height: 36px;
	}

	@include media-breakpoint-up(xxl) {
		font-size: 36px;
		line-height: 43px;
	}
}


@mixin klavika-lighter {
	@include media-breakpoint-up(xs) {
		font-family: 'alliance_no_2';
		font-style: normal;
		font-weight: 300;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.02em;
	}

	@include media-breakpoint-up(md) {
		font-size: 22px;
		line-height: 27px;
	}

	@include media-breakpoint-up(xxl) {
		font-size: 26px;
		line-height: 32px;
	}
}


@mixin roboto {
	
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 21px;
}


@mixin roboto-light {
	@include media-breakpoint-up(xs) {
		
		font-style: normal;
		font-weight: 300;
		font-size: 18px;
		line-height: 24px;
		letter-spacing: 0.02em;
	}

	@include media-breakpoint-up(md) {
		font-size: 22px;
		line-height: 27px;
	}

	@include media-breakpoint-up(xxl) {
		font-size: 26px;
		line-height: 32px;
	}
}

@mixin roboto-light-intro {
	font-family: 'alliance_no_1';

	@include media-breakpoint-up(xs) {
		font-size: 18px;
		line-height: 30px;
		
		// font-style: normal;
		// font-weight: 300;
		// font-size: 16px;
		// line-height: 19px;
		// letter-spacing: 0.02em;
	}

	// @include media-breakpoint-up(md) {
	// 	font-size: 18px;
	// 	line-height: 30px;
	// }

	// @include media-breakpoint-up(xl) {
	// 	font-size: 20px;
	// 	line-height: 28px;
	// }

	@include media-breakpoint-up(xxl) {
	}
}
