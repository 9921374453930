@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.etp-article {
  text-decoration: none;

  &.etp-article--featured {
    @include media-breakpoint-up(xl) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      column-gap: 24px;

      .etp-article-img {
        grid-column: 1;
        grid-row: 1 / 4;
        height: 100%;
        object-fit: cover;
      }

      .etp-article-date-wrapper {
        grid-column: 2;
        grid-row: 1;
        display: flex;
        align-items: flex-start;
      }

      .etp-article-title {
        grid-column: 2;
        grid-row: 2;
      }

      .etp-article-tags {
        grid-column: 2;
        grid-row: 3;
        display: flex;
        align-items: flex-end;
      }
    }
  }

  .etp-article-img {
    max-width: 100%
  }

  .etp-article-date-wrapper {
    color: $gray-700;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .etp-article-date {
    display: flex;
    align-items: center;
    flex: 1;
    column-gap: 10px;
  }

  .etp-article-date-line {
    height: 1px;
    flex: 1;
    background-color: $gray-700;
  }

  .etp-article-title {
    margin: 10px 0;
  }

  .etp-article-tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    .etp-article-tag {
      height: 24px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: bold;
      color: $gray-700;
      border: 1px solid $gray-700;
      padding: 0 10px;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}