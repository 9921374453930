@use '../../styles/vars' as *;

.article-load-more {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    padding-bottom: 120px;
    
    .load-more-progress {
        width: 250px;
        height: 2px;
        background-color: rgba($dark_blue, 0.25);
        margin-bottom: 16px;
        .load-more-progress-indicator {
            background-color: $midnight_blue;
            height: 100%;
            content: ''
        }
    }
}
