@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.etp-product-sub-text {
  color: $gray-500;
  font-size: 14px;
}

.etp-product-label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 8px;
  margin-bottom: 20px;

  .etp-product-label-square {
    height: 20px;
    width: 20px;
    border-radius: 4px;
  }
}

.etp-product-graph {
  margin: 100px 0 130px 0;
}