.etp-link {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    img {
      transform: translateX(10px);
    }
  }

  img {
    margin-left: 10px;
    transition: .3s transform ease-in;
  }
}

.black,
.blue {
  .etp-link-arrow-white {
    display: initial;
  }

  .etp-link-arrow-black {
    display: none;
  }
}

.gray,
.white {
  .etp-link-arrow-white {
    display: none;
  }

  .etp-link-arrow-black {
    display: initial;
  }
}