@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.featured-links-title {
  margin-bottom: 80px;
}

.featured-link {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 50px 0 70px 0;
  border-top: 1px solid $gray-900;

  .featured-link-graphic {
    display: none;
    margin-right: 30px;
  }

  .featured-link-body {
    max-width: 800px;

    .featured-link-title {

    }

    .featured-link-text {
      padding: 10px 0 20px 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .featured-link {

    .featured-link-graphic {
      display: initial;
    }
  }
}