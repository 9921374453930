

.justify-space-between {
	justify-content: space-between;
}


.social-media {
	display: flex;
	align-items: center;
  gap: 20px;
}

.image-link a {
	color: white;
}

.profile-img-container {
	height: 493px;
}

.profile-img-container img {
	object-fit: cover;
    object-position: center;
	height: 100%;
	width: 100%;
	background-color: #ebedf0;
}

.profile-details {
	display: flex;
	padding-top: 20px;
	padding-bottom: 40px;
	justify-content: space-between;
}

.team p:not(.pull-quote):not(.biography) {
	font-size: 20px;
	line-height: 28px;
	letter-spacing: 0.02em;
	color: inherit;
}

.profile-name {
	display: flex;
	flex-direction: column;
	color: black;
}

.profile-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #D3D3D3;
	padding-bottom: 15px;
	min-height: 46px;
}
.profile-footer .image-link a {
	color: #898989;
}

.profile-card {
	margin-top: 40px;
}

.profile-card.has-biography {
    cursor: pointer;
}

.profile-card.dim {
	opacity: 0.4;
}


.bio {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.bio-title {
	
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 12px;
	/* identical to box height, or 100% */

	letter-spacing: 0.1em;
	text-transform: uppercase;

	/* CS midnight blue */

	color: #122444;
	padding-right: 10px;
}

.bio-close-icon.expanded {
	transform: rotate(45deg);
}



.biography {
	
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 22px;
	padding-right: 40px;
	color: #122444;
}

.bb-primary {
	border-bottom: 3px solid #122444;
}
