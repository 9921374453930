@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.menu-container {
  padding: 1rem !important;
  background: #000000 !important;
  border: unset !important;
  border-radius: 0.5rem !important;
  box-shadow: rgba(0, 0, 0, 0.13) 0 6.4px 14.4px 0, rgba(0, 0, 0, 0.11) 0 1.2px 3.6px 0 !important;
  border: 1px solid $gray-900 !important;

  @include media-breakpoint-up(xl) {
    border: none !important;
  }
}

.menu-header {
  font-family: 'alliance_no_1', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  padding: 0 0 0.5rem 0;
  color: #6C6C6C;
}

.menu-separator {
  height: 1px;
  background: #18191B;
  width: 100%;
  margin: 0.5rem;
}

.menu-item {
  font-family: 'alliance_no_1', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 8px;
}

.menu-item:hover, .menu-item--active {
  background: #18191B;
}

.menu-item:focus-visible {
  outline: unset !important;
}