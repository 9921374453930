.choose-etp-btn {
  background: #000000;
  color: #FFFFFF;
  border: 0.75px solid #18191B;
  font-family: 'alliance_no_2', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  white-space: nowrap;
  text-decoration: none;
}

.choose-etp-btn > * {
  padding: 12px;
}

.separator {
  border-inline-end: 1px solid #18191B;
}