@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.carousel {
	@include widget;

	h5 + .carousel-cards {
		padding-top: 10px;
	}

	.carousel-cards {
		padding-bottom: 40px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		@include media-breakpoint-up(lg) {
			gap: 40px;
		}
		&.horizontal {
			flex-direction: row;

			// &:hover {
			// 	background: white;
			// }

			.carousel-card {
				flex: 1;
			}
		}
		.card-description {
			color: $gray-900;
		}
	}

	.carousel-card {
		border: 1px solid white;
		cursor: default;
		padding: 25px 20px;
		display: flex;
		flex-direction: column;
		gap: 20px;
    transition: filter 0.3s linear, opacity 0.3s linear;

		@include media-breakpoint-up(lg) {
			padding: 40px 32px;
		}

		.card-count {
			color: $gray-700;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 25px;
		}
	}

	// &.layout_1,
	// &.layout_5 {
	// 	.carousel-card {
	// 		.card-title {
	// 			font-family: 'alliance_no_2';
	// 			font-style: normal;
	// 			font-weight: 400;
	// 			font-size: 30px;
	// 			line-height: 36px;
	// 			letter-spacing: 0.03em;
	// 		}
	// 	}
	// }

	&.layout_2 {
		.carousel-card {
			.card-title {
				flex: none;
				order: 1;
				align-self: stretch;
			}
		}

		.paragraph {
			align-self: center;
		}
	}

	&.layout_3 {
		.carousel-cards {
			padding-top: 0px;
		}
	}

	&.layout_4 {
		padding-top: 0px;
		.carousel-card {
			border: 0 none;
			border-top: 1px solid white;
			flex-direction: row;
			padding: 20px 0;

			.card-title {
				font-family: 'alliance_no_2';
				font-style: normal;
				font-weight: 400;
				font-size: 80px;
				line-height: 96px;
				letter-spacing: 0.02em;
				text-transform: uppercase;
				align-self: end;
				width: 130px;
				text-align: right;
			}

			.card-description {
				font-family: 'alliance_no_2';
				font-style: normal;
				font-weight: 400;
				font-size: 24px;
				line-height: 32px;
				/* identical to box height, or 133% */

				letter-spacing: 0.03em;
				text-transform: uppercase;
				padding-bottom: 15px;
				align-self: end;
			}
		}
	}

	&.layout_5 {
		// background-color: hotpink;
		.carousel-cards {
			padding: 0px;
			.card-details {
				img {
					height: 40px;
					display: none;
				}
			}
			// .carousel-card {
			// 	border: 0 none;
			// 	border-top: 1px solid rgba($dark_blue, 0.25);
			// 	padding-left: 0px;
			// 	padding-right: 0px;
      //           flex-direction: row;

      //           .card-details {
      //               display: flex;
      //               gap: 20px;
      //               align-items: flex-start;

      //               img {
      //                   margin-top: 10px;
      //                   height: 65px;

      //                   @include media-breakpoint-up(lg) {
      //                       height: 105px;
      //                   }
      //               }

      //               .card-title {
      //                   font-weight: 300;
      //                   margin-bottom: 25px;
      //               }
    
      //               .card-description {
                        
      //                   font-style: normal;
      //                   font-weight: 300;
      //                   font-size: 16px;
      //                   line-height: 20px;
      //               }
      //           }
				
			// }
		}
	}
}

// .quote-container + .carousel.layout_4 {
//     @include media-breakpoint-up(xs) {
// 		margin-top: -60px;
// 	}

//     @include media-breakpoint-up(sm) {
//         margin-top: -140px;

//     }
// }

.light,
.white,
.gray {
	.carousel,
	&.carousel {
		.carousel-card {
			border-color: $black;
		}
	}
}
