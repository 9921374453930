@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.new-offering-summary {
  .row:not(:last-of-type) {
    margin-bottom: clamp(40px, 15vw, 120px);
  }
  .smaller-bottom-margin:not(:last-of-type) {
    margin-bottom: 80px;
  }
}

.bottom-border {
  border-bottom: 1px solid $gray-400;
}

.new-offering-card {
  border: 1px solid $gray-300;
  border-left: none;
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  position: relative;
  block-size: 100%;

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    height: 51%;
    width: 4px;
    left: 0;
    background-color: $blue;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 51%;
    width: 4px;
    left: 0;
    background-color: $black;
  }
}

.new-offering-card-link {
  font-size: 24px;
  line-height: 1.1;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
}

.new-offering-card-description {
  color: $gray-800;
}

.new-offering-card-stats {
  display: flex;
  justify-content: space-between;
  margin-block-start: auto;

  .new-offering-card-stats-label {
    color: $gray-900;
  }

  .new-offering-card-stats-value {
    font-weight: 600;
  }
}

.new-offering-large {
  display: flex;
  padding-top: 80px;

  p {
    margin-bottom: 40px;
  }
}

.new-offering-summary-big-left-indent {
  padding-left: 30px;
  @include media-breakpoint-up(md) {
    padding-left: 152px;
  }
}

.new-offering-summary-big-right-indent {
  padding-right: 30px;
  @include media-breakpoint-up(md) {
    padding-right: 152px;
  }
}

.new-offering-summary-small-left-indent {
  padding-left: 10px;
  @include media-breakpoint-up(md) {
    padding-left: 72px;
  }
}

.new-offering-summary-small-right-indent {
  padding-right: 10px;
  @include media-breakpoint-up(md) {
    padding-right: 72px;
  }
}

