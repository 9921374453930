@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.contact-form {
	padding: 10px -15px;
	@include media-breakpoint-up(sm) {
		padding: 10px 0px;
	}
	@include media-breakpoint-up(md) {
		padding: 10px;
	}
	@include media-breakpoint-up(lg) {
		padding: 50px 40px;
	}
	@include media-breakpoint-up(xl) {
		padding: 50px;
	}
	@include media-breakpoint-up(xxl) {
		padding: 50px 70px;
	}
	display: flex;
	flex-direction: column;
	row-gap: 30px;
}

.form-footer {
    margin-top: 30px;
	display: flex;
	flex-direction: column;
	row-gap: 30px;
	align-items: center;
	column-gap: 30px;

    .notice {
        flex: 1;
    }

    &+.form-footer {
        margin-top: 0;
    }

	@include media-breakpoint-up(sm) {
		flex-direction: row;
	}
}

.areas-of-interest {
	display: grid;
	flex-direction: column;
	row-gap: 20px;
	column-gap: 30px;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	padding-top: 60px;
	padding-bottom: 60px;
	border-bottom: 1px solid white;

	& > span {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 1;
	}

	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		& > span {
			grid-column-end: 4;
		}
	}

	@include media-breakpoint-up(lg) {
		display: flex;
		flex-direction: row;
	}
}

.ms-Checkbox {
	.ms-Checkbox-label,
	.ms-Checkbox-text {
		font-family: inherit;
		font-size: inherit;
		font-weight: inherit;
	}

	.ms-Checkbox-label {
		align-items: center;
		column-gap: 15px;
		.ms-Checkbox-checkbox {
			border-color: white;
			.ms-Checkbox-checkmark {
				font-weight: 900;
			}
		}

		.ms-Checkbox-text {
			color: white;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 12px;
			line-height: 12px;
			margin-left: 0px;
		}
	}

	&:hover {
		.ms-Checkbox-checkbox {
			border-color: white;

			.ms-Checkbox-checkmark {
				color: rgba(white, 0.6);
			}
		}

		.ms-Checkbox-text {
			color: white;
		}
	}

	&.is-checked {
		.ms-Checkbox-checkbox {
			background-color: white;
			.ms-Checkbox-checkmark {
				color: $midnight_blue;
			}
		}

		.ms-Checkbox-text {
			color: white;
		}
	}
}

.white, .light {
    .ms-Checkbox {
        .ms-Checkbox-label {
            .ms-Checkbox-checkbox {
                border-color: $midnight_blue;
                .ms-Checkbox-checkmark {
                    font-size: 0.75em;
                }
            }
            .ms-Checkbox-text {
                color: $midnight_blue
            }
        }

        &:hover {
            .ms-Checkbox-checkbox {
                border-color: $midnight_blue;
    
                .ms-Checkbox-checkmark {
                    color: rgba($midnight_blue, 0.6);
                }
            }
    
            .ms-Checkbox-text {
                color: $midnight_blue;
            }
        }

        &.is-checked {
            .ms-Checkbox-checkbox {
                background-color: $midnight_blue;
                .ms-Checkbox-checkmark {
                    color: white;
                }
            }
    
            .ms-Checkbox-text {
                color: $midnight_blue;
            }
        }
    }

    .areas-of-interest {
        border-bottom-color: $gray;
    }
}

.ms-TextField-errorMessage {
	position: absolute;
	color: $light_blue;
	
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 12px;
	display: flex;
	align-items: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
    padding-top: 10px;
    margin: 0px !important;
}

.checkbox-row{
	position: relative;
	margin-left: 15px;
	.check-box{
		position: absolute;
		top: 0px;
		left: 0px;
	}
	.checkbox-con{
		padding-left: 20px;
		p{
			margin: 0px;
		}
	}
}