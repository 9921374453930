@use '../../../styles/vars' as *;
@use '../../../styles/mixins' as *;

.nasdaq {
	font-family: 'alliance_no_2';
	font-size: 48px;
	line-height: 60px;
	text-transform: uppercase;
	color: $white;
	flex: 1;

	.font-light {
		font-size: 23px;
		line-height: 30px;
	}

	@include media-breakpoint-up(lg) {
		flex: initial;
	}
	padding: 16px 24px;
	border: 1px solid $white;
	align-self: start;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

	.nasdaq-details {
		margin-top: 5px;
		font-weight: 400;
		font-size: 16px;
		line-height: 25px;
		/* or 150% */

		display: flex;
		flex-direction: column;

		.nasdaq-details-small {
			font-size: 10px;
			line-height: 12px;
			letter-spacing: 0.02em;
			font-weight: 500;
			opacity: .8;
		}
	}

	.nasdaq-stats {
		
		font-style: normal;
		font-weight: 500;
		font-size: 10px;
		line-height: 15px;
		text-transform: uppercase;
		display: flex;
		padding-top: 30px;
		justify-content: space-between;

		.nasdaq-stat-item {
			display: flex;
			flex-direction: column;

			span:first-child {
				opacity: .8;
			}

			span + span {
				
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 30px;
				margin-top: 5px;
			}

			& + .nasdaq-stat-item {
				margin-left: 40px;
				margin-right: 10px;
			}
		}
	}
}

// .extra-content {
// 	display: flex;
// 	justify-content: flex-end;

// 	@include media-breakpoint-up(lg) {
// 		padding-top: 120px;
// 	}
// }

.nasdaq-disclaimer {	
	font-size: 14px;
	line-height: 20px;
}
