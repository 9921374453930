@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.broker-contact-form {
  padding: 0px;
  @include media-breakpoint-up(sm) {
    padding: 10px 0px;
  }
  @include media-breakpoint-up(md) {
    padding: 10px;
  }
  @include media-breakpoint-up(lg) {
    padding: 0px 40px;
  }
  @include media-breakpoint-up(xl) {
    padding: 0px;
  }
  @include media-breakpoint-up(xxl) {
    padding: 0px 70px;
  }
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  .form-space {
    padding: 160px 0px;
    .form {
      padding-top: 0;
      .form-input label {
        font-weight: 500 !important;
      }
    }
    .ms-Dropdown-container .ms-Dropdown .ms-Dropdown-title {
      padding: 12px 16px !important;
    }
  }
}

.form-title h5 {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.24px;
  text-transform: uppercase;
}

.body-intro {
  font-size: 32px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 40px !important;
}

.form-footer-box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  @include media-breakpoint-up(xl) {
    justify-content: end;
    align-items: baseline;
  }
}

.form-footer-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-right: 20px;
  a {
    text-decoration: underline;
    padding: 0px 5px;
  }
}

.ms-TextField-errorMessage,
.ms-Dropdown-container div[role="alert"] {
  position: absolute;
  color: $light_blue;

  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding-top: 2px;
  margin: 0px !important;
}


.broker-contact-form-confirmation{
  padding: 80px;
}