.etp-mvrv-graph {
  &__discover {
    padding: 28px 40px;
    background-color: #121212;
    display: flex;
    justify-content: space-between;
    p {
      margin: 0;
    }
  }
}
