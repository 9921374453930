@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.etp-article-category-selector {
  display: flex;
  align-items: center;
  gap: 2px;

  .etp-article-category-selector-title {
    font-size: 14px;
    color: $gray-600;
    font-weight: 600;
  }

  .ms-Dropdown-container {
    .ms-Dropdown {
      min-width: 210px;

      .ms-Dropdown-caretDownWrapper {
        right: unset;
        left: 10px;

        .ms-Dropdown-caretDown {
          font-weight: 600;
          color: $gray-600;
        }
      }

      .ms-Dropdown-title {
        font-weight: 600;
        border: none;
        padding: 9px 16px 9px 36px;
      }
    }
  }

}
