@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.scrollbar {
  white-space: nowrap;
  height: auto !important;

  .article {
    white-space: normal;
  }

  .scrollbar-view {
    overflow-y: hidden !important;
    position: static !important;
    padding-bottom: 60px;

    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
      height: 0px;
      visibility: hidden;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0);
      box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(0, 0, 0, 0);
    }

    & > .scrollbar-view-container:after {
      content: '';
      display: inline-flex;
      min-width: 80px;
    }

    .swipe-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      gap: 10px;
      position: absolute;
      bottom: 23px;
      right: 0;
      display: none;

      .swipe-icon {
        img:nth-child(2) {
          opacity: 0.2;
        }

        img:nth-child(3) {
          opacity: 0.1;
        }
      }

      &:after {
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: 80px;

      & > *:after {
        min-width: 120px;
      }

      .swipe-right {
        display: none;
      }
    }
  }

  & > .scrollbar-track {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 10px;
    background: $gray-900;
    border-radius: 24px;
  }

  .scrollbar-thumb {
    color: white;
    display: inline-flex !important;
    justify-content: space-between;
    align-items: center;
    min-width: 60px;
    background-color: $black;
    height: 3px;
    margin-bottom: 12px;
    border-radius: 24px;


    svg {
      display: none;
    }
  }

  &.hide {
    .scrollbar-view {
      padding-bottom: 0;
    }

    .scrollbar-view:after, .scrollbar-track, .swipe-right {
      display: none;
    }
  }
}

.gray,
.white {
  .scrollbar {
    > .scrollbar-track {
      background: $gray-200;
    }
  }
}

.black,
.blue {
  .scrollbar {
    .scrollbar-thumb {
      background: $white;
    }
  }
}

.transparent {
  .scrollbar {

    .scrollbar-thumb {
      color: $midnight_blue;
      background-color: white;
    }
  }
}
