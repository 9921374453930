@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.fair-value-header {
  margin-bottom: clamp(80px, 10vw, 150px);
}

.fair-value-footer {
  margin-top: clamp(80px, 10vw, 150px);
  color: $gray-600;
  font-size: 14px;
}

.fair-value-calculation {
  justify-content: center;

  > * {
    display: inline;
  }

  > *:not(:last-of-type) {
    display: inline-flex;
    margin-right: 12px;
  }

  .fair-value-value {
    white-space: nowrap;
  }

  .fair-value-symbol {
    color: $gray-900;
  }

  .fair-value-group {
    display: inline-flex;
    flex-direction: column;
    row-gap: 8px;

    .fair-value-value {
      color: $gray-600;
    }

    .fair-value-border {
      > div {
        &:first-of-type {
          height: 8px;
          border: 0.5px solid $gray-300;
          border-top: none;
        }

        &:nth-of-type(2) {
          width: 0.5px;
          height: 8px;
          background-color: $gray-300;
          margin: 0 auto;
        }
      }
    }

    .fair-value-group-info {
      font-size: 10px;
      text-align: center;
      margin: 0 -25px;
    }
  }
}
