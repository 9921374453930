@use '../../styles/vars'as *;
@use '../../styles/mixins' as *;

.box-sets {
	@include widget;

    .boxsets-contaienr {
        padding-bottom: 60px;
    }
	.boxsets-list {
		padding-top: 60px;
		padding-bottom: 60px;
        gap: 40px;
        display: flex;
        flex-wrap: nowrap;

		.boxset {
            padding: 40px;
            padding-top: 220px;
            padding-right: 60px;
            width: calc(25% - 80px);
            text-decoration: none;
            white-space: pre-wrap;
            position: relative;
            display: flex;
            flex-direction: column;
            height: 390px;
            justify-content: space-between;
            background-repeat: no-repeat;
            background-size: cover;

            .boxset-title {
                z-index: 1;
                position: relative;
            }

            .boxset-forward {
                position: relative;
                align-self: end;
            }

            &:before {
                content: '';
                position: absolute;
                z-index: 0;
                top: 70px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                background: linear-gradient(194.03deg, rgba($midnight_blue, 0) 14.95%, $midnight_blue 80.37%);
            }
		}
	}
}
