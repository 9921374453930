@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.twitter-feed {
	padding-top: 120px;
	padding-bottom: 140px;

	.twitter-feed-title {
		display: flex;
		flex-direction: column;
		gap: 30px;
		justify-content: center;
	}

	.twitter-feed-container {
		display: flex;
		flex-wrap: nowrap;

		.tweet {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 30px;
			padding-left: 40px;
			white-space: pre-wrap;

            @include media-breakpoint-up(xs) {
                width: 100%;
                min-width: 400px;
            }

            @include media-breakpoint-up(lg) {
                width: calc(50% - 40px);
                min-width: calc(50% - 40px);
            }
			
			display: inline-flex;

			background: white;
			box-shadow: 30px 30px 50px -30px rgba(0, 0, 0, 0.15);

			& + .tweet {
				margin-left: 40px;
			}

			.tweet-header {
				display: flex;
				gap: 16px;
				height: auto;
				.tweet-logo {
					height: 30px;
				}

				.tweet-author {
					flex: 1;
					display: flex;
					flex-direction: column;
                    gap: 3px;

					
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 16px;

					text-decoration: none;

					.tweet-author-subtext {
						
						font-style: normal;
						font-weight: 700;
						font-size: 11px;
						line-height: 13px;

                        color: #687684;
                        display: flex;
                        gap: 5px;

                        span + span::before {
                            content: '•';
                            padding-right: 6px;
                        }
					}
				}

				.twitter-logo {
					align-self: center;
				}
			}

			.text {
				
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;

				a {
					text-decoration: none;
					color: #7dc7f5;
				}
			}
		}
	}
}
