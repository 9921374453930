@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.offer-summary {
	@include widget;

	// home
	&.layout_1,
	&.layout_2,
	&.layout_3 {
		.offering-items {
			display: grid;
			row-gap: 40px;
			column-gap: 40px;

			.card-content {
				justify-content: space-between;
			}

			.card-title {
				display: flex;
				justify-content: space-between;
			}

			@include media-breakpoint-up(xs) {
				.card {
					min-height: 160px;
					.card-content.external {
						row-gap: 15px;

						.card-icon img, .card-icon-hover img {
							max-height: 40px;
						}

                        .card-icon-hover {
                            display: none;
                        }
					}

                    &:hover {
                        .card-content.external {
                            .card-icon {
                                display: none;
                            }
    
                            .card-icon-hover {
                                display: initial;
                            }
                        }
                    }
				}
			}

			@include media-breakpoint-up(md) {
				grid-template-columns: repeat(2, minmax(0, 1fr));
				// grid-template-rows: 80px auto 80px auto 80px auto;
				// &:before,
				// &:after {
				// 	content: '';
				// 	height: 100%;
				// }
				// &:before {
				// 	order: 1;
				// }

				// &:after {
				// 	order: 6;
				// }

				// .card {
				// 	order: 0;
				// 	grid-row: span 2;
				// 	&:nth-of-type(1) {
				// 		grid-column: 1 / 2;
				// 		grid-row: 2 / 4;
				// 	}
				// 	&:nth-of-type(2) {
				// 		grid-column: 2 / 3;
				// 		grid-row: 1 / 3;
				// 	}
				// 	&:nth-of-type(3),
				// 	&:nth-of-type(4),
				// 	&:nth-of-type(5) {
				// 		grid-row: span 2;
				// 	}
				// }
			}


			@include media-breakpoint-up(xl) {
				margin-top: 40px;
				.card-stat {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				// grid-template-rows: repeat(8, 70px);

				// .card {
        //             &:nth-of-type(1) {
				// 		grid-column: 1 / 2;
				// 		grid-row: 3 / 7;
				// 	}
				// 	&:nth-of-type(2) {
				// 		grid-column: 2 / 3;
				// 		grid-row: 2 / 6;
				// 	}
				// 	&:nth-of-type(3) {
				// 		grid-column: 3 / 4;
				// 		grid-row: 1 / 5;
				// 	}

				// 	&:nth-of-type(4) {
				// 		grid-row: 3 / 7;
				// 	}

				// 	&:nth-of-type(5) {
				// 		order: 15;
				// 		grid-row: span 4;
				// 	}
				// }
			}
		}
	}

	// products and services
	// &.layout_3 {
	// 	padding-top: 0px;
	// 	.offering-items {
	// 		display: grid;
	// 		row-gap: 30px;
	// 		column-gap: 20px;

	// 		.card-icon {
	// 			img {
	// 				// height: 112px;
	// 				width: 112px;
	// 			}
	// 		}

	// 		@include media-breakpoint-up(sm) {
	// 			grid-template-columns: repeat(2, minmax(0, 1fr));
	// 			grid-template-rows: 140px auto 140px auto 140px auto;
	// 			&:before,
	// 			&:after {
	// 				content: '';
	// 				height: 100%;
	// 			}
	// 			&:before {
	// 				order: 1;
	// 			}

	// 			&:after {
	// 				order: 6;
	// 			}

	// 			.card {
	// 				order: 0;
	// 				grid-row: span 2;
	// 				&:nth-of-type(1) {
	// 					grid-column: 1 / 2;
	// 					grid-row: 2 / 4;
	// 				}
	// 				&:nth-of-type(2) {
	// 					grid-column: 2 / 3;
	// 					grid-row: 1 / 3;
	// 				}
	// 				&:nth-of-type(3),
	// 				&:nth-of-type(4),
	// 				&:nth-of-type(5) {
	// 					grid-row: span 2;
	// 				}

	// 				&.external {
	// 					.card-forward {
	// 						position: relative;
	// 					}
	// 				}
	// 			}
	// 		}

	// 		@include media-breakpoint-up(md) {
	// 			column-gap: 30px;
	// 		}

	// 		@include media-breakpoint-up(xxl) {
	// 			column-gap: 40px;
	// 			row-gap: 40px;
	// 		}
	// 	}
	// }

	// about
	&.layout_4 {
		.offering-items {
			padding-top: 40px;
			.offering-item {
				display: flex;
				padding-top: 40px;
				padding-bottom: 40px;
				border-top: 1px solid rgba($dark_blue, 0.25);
				gap: 40px;
				text-decoration: none;
				transition: background-color 0.4s linear, color 0.4s linear;

				.offering-title {
					text-transform: uppercase;
					flex: 1;
					align-self: center;
				}
			}
		}
	}
}
