@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.investment-portfolio {
	@include media-breakpoint-up(lg) {
		.portfolio-cards {
			margin-left: -15px;
			margin-right: -15px;
		}
	}
}

.investment-portfolio-categories-dropdown {
	display: flex;
	padding-bottom: 25px;

	.ms-Dropdown-container {
		width: 100%;

		@include media-breakpoint-up(sm) {
			width: auto;
		}
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.investment-portfolio-categories {
	display: none;
	flex-direction: row;
	justify-content: flex-start;
	padding: 30px 30px 30px 30px;
	margin: 0px -30px;

	.button {
		flex: 0;
		border-width: 1px;
		cursor: pointer;
	}

	@include media-breakpoint-up(lg) {
		display: flex;
		margin: 0px -15px;
		column-gap: 10px;
		padding: 0 0 40px;
	}
}

.investment-portfolio-info {
	padding-top: 80px;
	display: flex;
	flex-direction: column;
	row-gap: 30px;
	@include media-breakpoint-up(lg) {
		padding-bottom: 80px;
	}

	h2 {
		margin-bottom: 20px;
	}

	p {
		margin: 0;
	}

	> div {
		
		@include media-breakpoint-up(lg) {
			display: flex;
			justify-content: space-between;

			h2 {
				max-width: 480px;
				flex-shrink: 0;
			}
		}
		
	}
}

.investment-portfolio-investment {
	border-top: 1px solid $black;
	padding: 30px 0 90px;
	margin-top: 40px;

	@include media-breakpoint-up(lg) {
		// padding: 70px 45px;
		padding-bottom: 200px;
	}
}

.investment-portfolio-header {
	font-size: 20px;
	line-height: 30px;
	margin-bottom: 15px;
}

.portfolio-cards {
	display: grid;
	padding: 0 !important;
	border-left: 0 none;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	box-sizing: border-box;


	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		row-gap: 40px;
		column-gap: 20px;

	}

	@include media-breakpoint-up(lg) {
		grid-template-columns: repeat(3, minmax(0, 1fr));
		row-gap: 80px;
		column-gap: 80px;

		.show-more-portfolio {
			height: 120px;
		}
	}


	.portfolio-card {
		display: flex;
		padding: 20px 0 40px;
		cursor: pointer;
		border-top: 1px solid $black;

		.portfolio-title {
			font-family: 'alliance_no_2';
			font-weight: 400;
			font-size: 20px;
			line-height: 30px;
			display: block;
		}

		.portfolio-item {
			font-size: 14px;
			line-height: 20px;
			color: $gray-800;
			margin-bottom: 0;
			margin-top: 10px;
		}

		.portfolio-img {
			max-width: 80px;
			height: 80px;
			margin-right: 20px;
		}
	}

	.show-more-portfolio {
		box-shadow: 0 0 0 1px $black;
		padding: 30px;

		span {
		}
		button {
			display: block;
			margin: 15px 0 0;
			padding: 0;
			background: none;
			border: none;
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.investment-portfolio {
	position: relative;
	padding: 0 !important;
}

.modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-x: hidden;
	display: flex;
	justify-content: center;
	align-items: center;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: $gray;
		opacity: 0.95;
	}

	.modal-popup {
		background-color: white;
        width: 80%;
        @include media-breakpoint-up(lg) {
		    width: 60%;
        }
		height: auto;
		max-height: 80%;
		z-index: 1;
		padding: 60px;

		.modal-header {
			display: flex;
			justify-content: space-between;

			img {
				height: 80px;
			}
		}

		.modal-close:after {
			display: inline-block;
			content: '\00d7'; /* This will render the 'X' */
			cursor: pointer;
			font-size: 25px;
		}

		.modal-body {
			display: flex;
			flex-direction: column;
			gap: 40px;
			padding-bottom: 40px;
		}

		.modal-footer {
			display: flex;
			gap: 20px;
		}
	}
}

.portfolio-link {
	font-family: 'alliance_no_2';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 24px;
	/* identical to box height, or 120% */

	letter-spacing: 0.03em;
}

.tag {
	background-color: $midnight_blue;
	color: white;
	padding: 2px 10px;
	border-radius: 20px;

	
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 15px;
	/* identical to box height, or 100% */

	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;

	display: inline-block;

    &.viewed {
        background-color: $blue;
    }
}

.tag--white {
	border: 1px solid #A0A0A0;
	color: #898989;
	background-color: white;
	border-radius: 100px;
}