@use '../../styles/mixins' as *;

@include media-breakpoint-up(xs) {
    .paragraph {
        display: flex;
        flex-direction: column;
        gap: 20px;
    
        p {
            font-size: 18px;
            line-height: 30px;
            color: inherit;
            white-space: pre-wrap;
            margin: 0;
        }
    
        .button {
            align-self: start;
        }
    }
    
    h5 + .paragraph {
        gap: 20px;
        // h2 {
        //     font-family: 'alliance_no_2';
        //     font-style: normal;
        //     font-weight: 100;
        //     font-size: 46px;
        //     line-height: 55px;
        //     letter-spacing: 0.02em;
        // }
    }
}

@include media-breakpoint-up(lg) {
    .paragraph.sticky {
        position: sticky;
        top: 40px;
    }
}

@include media-breakpoint-up(xl) {
    .paragraph {
        padding-right: 160px;
    }
}

