@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.key-information-title {
  margin-bottom: 100px;
}

.key-information-options {
  display: flex;

  flex-direction: column;
  row-gap: 25px;

  @include media-breakpoint-up(xl) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .key-information-dropdown {
    display: flex;
    padding-bottom: 25px;

    .ms-Dropdown-container {
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }

    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  .key-information-selectors {
    display: none;
    gap: 20px;

    @include media-breakpoint-up(xl) {
      display: flex;
    }

    .button {
      border-width: 1px;

      h3 {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      }

      &.selected {
        background-color: $midnight_blue;
        color: white;
      }
    }
  }
}

.key-information-content {
  padding-top: 40px;

  .document-link {
    margin-top: 60px;
  }

  .black & {
    .document img {
      filter: invert(1);
    }
  }
  .documents-list {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    .document {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-decoration: none;
      padding: 30px 20px;
      border: 1px solid $black;

      &.bottom {
        border: 0;
        border-bottom: 1px solid black;
        padding: 16px 0;
        text-transform: none;
      }

      display: flex;
      align-items: center;
      column-gap: 10px;

      .black & {
        border-color: $gray-900;

        &:hover {
          background: $gray-900;
        }
      }
    }
  }
}

.key-information-index {
  border-bottom: 1px solid white;
  display: flex;
  padding-bottom: 30px;
  column-gap: 30px;
}

.key-information-countries {
  display: flex;
  column-gap: 26px;
  row-gap: 12px;
  justify-content: space-between;
  flex-wrap: wrap;

  @include media-breakpoint-up(sm) {
    justify-content: flex-start;
  }

  .key-information-country {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
}
