@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.graph-header {
  margin-bottom: 60px;
}
h5 + .statistics {
  margin-top: 30px;
}
.statistics {
  display: flex;
  flex-direction: column;
  .statistic {
    border-top: 1px solid $gray-700;

    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;

    padding-top: 20px;
    padding-bottom: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;

    .statistic-detail {
      font-weight: 300;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      flex-wrap: wrap;
    }
  }
  .entitlement_historical_text {
    padding: 24px;
    background: #18191b;
    display: block;
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
    }
    div {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      display: grid;
      gap: 5px;
      justify-content: end;
      a {
        font-weight: 400;
        text-decoration: none;
      }
    }
  }
}

.white,
.light {
  .statistics {
    border-top: 1px solid $midnight_blue;
    .statistic {
      border-top: 0 none;
      border-bottom: 1px solid rgba($dark_blue, 0.25);
    }
  }
}

.performance-sub-text {
  font-size: 14px;
  color: $gray-500;
}

.line-graph-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: calc(320 / 500 * 100%);
  vertical-align: top;
  overflow: visible;
  &.series {
    padding-bottom: calc(320 / 500 * 100%);
  }
  .line-graph {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    path.disabled {
      opacity: 0;
    }

    .x-axis,
    .y-axis {
      path.domain {
        stroke: transparent;
      }
      .tick {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 30px;
        text-transform: uppercase;
        line {
          stroke: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}

.legend {
  padding-top: 10px;
  display: flex;
  column-gap: 20px;
  row-gap: 10px;
  padding-left: 100px;
  flex-wrap: wrap;

  .legend-item {
    @include roboto;
    cursor: pointer;
    display: flex;
    column-gap: 10px;
    // span {
    //     display: inline-block;
    //     line-height: 25px;
    // }
    // & >* {
    // vertical-align: middle;
    // line-height: 25px;
    // }
    .legend-indicator {
      width: 25px;
      height: 25px;
      display: inline-block;
    }

    .legend-text {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    img {
      align-self: start;
      padding-top: 5px;
    }

    &.disabled {
      opacity: 0.6;
    }
  }
}

.graph-disclaimer {
  @include roboto;

  padding-left: 100px;
}
