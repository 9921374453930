@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.etp-broker-search-title {
  margin-bottom: clamp(40px, 10vw, 100px);
}

.etp-broker-search {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: fit-content(130px) fit-content(600px) fit-content(100px) fit-content(100px);
  gap: 20px;

  &.etp-broker-search-loading {
    .etp-broker-search-pagination {
      visibility: hidden;
    }
  }

  .etp-broker-search-filters {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;

    .etp-input-group {
      flex: 1 0 300px;
    }
  }

  .broker-not-found {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .etp-broker-search-brokers {
    grid-column: 1;
    grid-row: 2;
    display: grid;
    grid-template-columns: repeat(2, fit-content(160px));
    grid-template-rows: repeat(4, fit-content(160px));
    gap: clamp(20px, 5vw, 40px);
    justify-content: center;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(5, fit-content(120px));
    }

    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    .etp-broker-search-broker {
      width: 100px;
      height: 100px;
      border: 1px solid $gray-200;

      @include media-breakpoint-up(md) {
        width: 140px;
        height: 140px;
      }

      @include media-breakpoint-up(xl) {
        width: 120px;
        height: 120px;
      }

      @include media-breakpoint-up(xxl) {
        width: 160px;
        height: 160px;
      }

      > img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .etp-broker-search-pagination {
    grid-column: 1;
    grid-row: 3;
  }

  .etp-broker-search-alt-button {
    grid-column: 1;
    grid-row: 4;
    display: flex;
    align-items: flex-end;

    > a {
      background-color: $gray-0;
      flex: 1 0 auto;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: 340px 1fr;
    grid-template-rows: fit-content(400px) fit-content(400px) fit-content(100px);
    column-gap: 80px;

    .etp-broker-search-filters {
      grid-column: 1;
      grid-row: 1;
      flex-direction: column;

      .etp-input-group {
        width: 100%;
        flex: 0 0 auto;
      }
    }

    .etp-broker-search-brokers {
      grid-column: 2;
      grid-row: span 2;
    }

    .etp-broker-search-pagination {
      grid-column: 2;
      grid-row: 3;
    }

    .etp-broker-search-alt-button {
      grid-column: 1;
      grid-row: 2;
    }
  }
}