@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.etp-newsletter {
  .newsletter_terms_and_condition {
    flex-direction: column;
    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }
    .col-10 {
      p {
        margin: 0px;
      }
    }
    .submit_button {
      padding: 10px 0px;
    }
  }
}
