@use '../../styles/mixins' as *;

.etp-physical-list-title {
  margin-bottom: 160px;
}

.etp-physical-list-options {
  display: flex;
  column-gap: 15px;
  row-gap: 15px;
  align-items: center;
  flex-wrap: wrap;
}

.etp-physical-products {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;

  > a {
    height: unset;
  }
}