@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.etp-big-number {

  .etp-big-number-text {
    display: flex;
    align-items: baseline;
    column-gap: 12px;
  }

  h1 {
    font-size: clamp(52px, 15vw, 104px);
    line-height: 1;
  }

  h2, .h2 {
    font-size: clamp(34px, 15vw, 72px);
    line-height: 1;
  }

  h3:not(.h2) {
    font-size: clamp(16px, 15vw, 32px);
    line-height: 1;
  }

  .etp-big-number-info {
    margin-top: 10px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    > span {
      font-size: clamp(14px, 5vw, 18px);
      margin-right: 6px;
    }

    > img {
      margin-top: 2px;
    }

    .etp-big-number-info-tooltip {
      position: absolute;
      top: 30px;
      left: 0;
      background-color: $black;
      color: $gray-200;
      border: 1px solid $gray-200;
      font-size: 14px;
      padding: 8px 12px;
      display: none;
      line-height: 1.8;

      &--visible {
        display: initial;
      }
    }
  }
}