@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.featured-products {
	@include widget;
}

.featured-products-list.row {
  row-gap: 50px;
}

.featured-products-list,
.products-list {
	padding-top: 10px;
	padding-bottom: 10px;

	.featured-product {
		text-decoration: none;
		// padding: 40px;
		display: flex;
		width: 100%;
		flex-direction: column;
		gap: 10px;

		// border: 3px solid $midnight_blue;
		// border-radius: 0px;

		transition: background-color 0.3s linear, border-radius 0.3s linear;

        .product-graph + h4 {
            // padding-top: 20px;
        }
        .product-graph {
            background: url('../../../public/images/grid.svg') no-repeat center center;
            background-size: contain;
            margin-bottom: 10px;
        }

        p {
            margin: 0;
        }

		.featured-product-img,
		.featured-product-img-hover {
			max-width: 100%;
		}

		.featured-product-img-hover {
			display: none;
		}

		.product-line {
			stroke: $midnight_blue;
			transition: stroke 0.3s linear;
		}

		.featured-product-details {
			border-top: 1px solid $midnight_blue;
			.featured-product-detail {
				padding-top: 15px;
				padding-bottom: 15px;
				border-top: 1px solid rgba($dark_blue, 0.25);
				display: flex;
				justify-content: space-between;

				span {
					
					font-style: normal;
					font-weight: 400;
					font-size: 15px;
					line-height: 21px;
					a {
						text-decoration: none;
					}

					.fact-sheet {
						display: flex;
						align-items: center;
						gap: 5px;

						.featured-product-img,
						.featured-product-img-hover {
							height: 13px;
						}
					}
				}
				span:first-child {
					font-weight: 600;
				}
			}
		}

        .more-info {
            position: relative;
            min-height: 65px;
            flex: 1;
    
            &::before,
            &::after {
                content: '';
                position: absolute;
                transition: border-color 0.3s linear;
    
            }
    
            &::before {
                bottom: 0;
                right: 0;
                border-left: 25px solid white;
                border-top: 25px solid transparent;
                border-bottom: 25px solid transparent;
            }
    
            &::after {
                right: 2px;
                bottom: 2px;
                border-left: 23px solid $midnight_blue;
                border-top: 23px solid transparent;
                border-bottom: 23px solid transparent;
            }
        }

		// &:hover {
		// 	color: white;
		// 	background-color: $midnight_blue;
		// 	// border-radius: 30px;

		// 	// @include media-breakpoint-up(lg) {
		// 	// 	border-radius: 40px;
		// 	// }
		// 	h5 {
		// 		color: white;
		// 	}
		// 	.featured-product-img {
		// 		display: none;
		// 	}

		// 	.featured-product-img-hover {
		// 		display: block;
		// 	}

		// 	.product-line {
		// 		stroke: white;
		// 	}

		// 	.featured-product-details {
		// 		border-color: white;
		// 		.featured-product-detail {
		// 			border-color: rgba(white, 0.25);
		// 		}
		// 	}

        //     .more-info {
        //         &::before {
        //             border-left-color: $midnight_blue;
        //         }
        //         &::after {
        //             border-left-color: white;
        //         }
        //     }
		// }
	}

    @include media-breakpoint-up(lg) {
      .featured-product {
        row-gap: 20px;
        .product-graph {
            margin-bottom: 20px;
        }
      }
    }
}

.white, .light, .gray {
    .featured-products-list, .products-list {
        .featured-product {
            .more-info {
                &::before {
                    border-left-color: $midnight_blue;
                }
                &::after {
                    border-left-color: white;
                }
            }

            // &:hover {
            //     .more-info {
            //         &::before {
            //             border-left-color: white;
            //         }
            //         &::after {
            //             border-left-color: $midnight_blue;
            //         }
            //     }
            // }
        }
    }
}

.gray {
    .featured-products-list, .products-list {
        .featured-product {
            .more-info {
                &::after {
                    border-left-color: $gray;
                }
            }

            // &:hover {
            //     .more-info {
            //         &::before {
            //             border-left-color: $gray;
            //         }
            //     }
            // }
        }
    }
}

.products-list {
	padding: 0px 40px;
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding-top: 60px;
}

.related-content {
	.products-list {
		padding-bottom: 40px;
	}
}


.transparent, .blue {
    .featured-products-list {
        .featured-product {
            border-color: white;
            h4 {
                color: $white
            }
            p {
                color: $gray-400
            }
            .product-graph path {
                stroke: white;
            }

            .featured-product-img {
                display: none;
            }

            .featured-product-img-hover {
                display: block;
            }

            .featured-product-details {
                border-color: white;
                .featured-product-detail {
                    border-color: rgba(white, 0.25);
                }
            }

            // &:hover {
            //     color: $midnight_blue;
            //     background-color: white;

            //     h5 {
            //         color: $midnight_blue
            //     }

            //     .product-graph path {
            //         stroke: $midnight_blue;
            //     }

            //     .featured-product-img {
            //         display: block;
            //     }
    
            //     .featured-product-img-hover {
            //         display: none;
            //     }
    
            //     .featured-product-details {
            //         border-color: $midnight_blue;
            //         .featured-product-detail {
            //             border-color: rgba($midnight_blue, 0.25);
            //         }
            //     }
            // }
        }
    }
}