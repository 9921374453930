@use '../../styles/mixins' as *;
@use '../../styles/vars' as *;

.etp-key-information {
  border-top: 1px solid $gray-800;
  padding-top: 50px;

  .etp-key-information-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    img {
      height: 14px;
      filter: brightness(100%);
      transition: filter 0.3s ease;
    }

    > * {
      display: flex;
      column-gap: 8px;

      &:hover {
        img {
          filter: brightness(0%);
        }
      }
    }
  }
}