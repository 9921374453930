@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.etp-related-products-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, 400px);
  justify-content: center;
  gap: 20px;
}

.etp-related-products-text {
  font-size: 18px;
  margin: 20px 0;
}

.etp-related-products-disclaimer {
  font-size: 14px;
  font-weight: 400;
  color: $gray-800;
  margin-top: 70px;
}