
// Colors
$black: #000;
$white: #fff;
$midnight_blue: #122444;
$gray: #ebedf0;
$light_blue: #7dc7f5;
$dark_blue: #1E2E3F;
$mid_blue: #525AE1;
$blue: #0C28CF;
$green: #CFFF24;

$gray-0: #F5F5F5;
$gray-100: #EDEDED;
$gray-200: #E1E1E1;
$gray-300: #D3D3D3;
$gray-400: #C4C4C4;
$gray-500: #B3B3B3;
$gray-600: #A0A0A0;
$gray-700: #898989;
$gray-800: #6C6C6C;
$gray-900: #3F3F3F;
$gray-950: #1A1A1A;

// grid
$gutter: 40px;
$col-gutter: calc($gutter / 2);

// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;