@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.white + .knowledge-bytes {
	border-top: 1px solid $gray-300;
}

.tooltip-bubble {
	cursor: pointer;
	text-decoration: underline $midnight_blue dotted;
    text-underline-offset: 5px
}

.question {
	.tooltip-bubble {
		text-decoration: underline white dotted;
	}
}

.tooltip-bubble:hover {
	color: $light_blue;
	text-decoration: underline $light_blue dotted;
}

.knowledge-card-bubble {
	border-radius: 20px 20px 20px 0px;
	border: 0 none !important;
	.ms-Callout-main {
		background-color: white;
		border-radius: 20px 20px 20px 0px;
		border: 2px solid $midnight_blue;
		.ms-TeachingBubble-subText {
			color: $midnight_blue;
			display: flex;
			flex-direction: column;
			row-gap: 24px;

			p {
				overflow: hidden;
				-webkit-line-clamp: 3;
				display: -webkit-box;
				line-clamp: 3;
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;

				@include roboto;
			}
		}
	}
}

.knowledge-card-link {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	text-decoration: none;
}

.knowledge-bytes {
	@include widget;

	&.topics {
		padding-top: 50px;
	}
	.headline {
		padding-top: 40px;
		padding-bottom: 120px;
		row-gap: 60px;

		p {
			margin: 0;
		}
	}

	.bytes {
		display: grid;
		grid-template-columns: repeat(1, minmax(0, 1fr));
		row-gap: 20px;

		&.horizontal {
			display: flex;
			grid-template-columns: none;
			padding: 0px;
			column-gap: 20px;
			.knowledge-byte {
				min-width: 100%;
				.knowledge-byte-title {
					white-space: initial;
				}
			}
		}
	}

	.bytes,
	.topics {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.knowledge-bytes-search {
		border-top: 1px solid white;
		padding-top: 60px;
		padding-bottom: 60px;
		background-color: rgba($midnight_blue, 0.8);
		position: relative;

		.search {
			display: flex;
			align-items: center;
			column-gap: 50px;
			.search-box {
				flex: 1;

				input {
					width: 100%;
					color: white;
					background: $midnight_blue;
					border: 0 none;
					font-weight: 300;
					font-size: 28px;
					line-height: 39px;
					letter-spacing: 0.02em;
					caret-color: white;
					outline: none;
				}
			}
		}

		.search-dropdown {
			visibility: hidden;
			position: absolute;
			top: 100%;
			right: 0;
			left: 0;
			z-index: 1;

			.search-item {
				@include roboto-light-intro;
				padding-top: 25px;
				padding-bottom: 25px;
				&.question {
					border-top: 1px solid #ffffff;
				}

				&.answer {
					background-color: white;
					color: $midnight_blue;
					border-top: 1px solid $midnight_blue;
				}

				&:last-child {
					border-bottom: 1px solid $midnight_blue;
				}

				.search-link {
					text-decoration: none;
					padding-left: 110px;
					display: flex;
					justify-content: space-between;
					align-items: center;

					.search-link-button {

						font-style: normal;
						font-weight: 700;
						font-size: 12px;
						line-height: 16px;
						letter-spacing: 0.1em;
						text-transform: uppercase;
						display: flex;
						column-gap: 15px;
						align-items: center;
					}
				}
			}
		}

		&.open {
			.search-dropdown {
				visibility: visible;
				background: $midnight_blue;
				// opacity: 0.9;
			}
		}
	}

	.search-open {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			inset: 0;
			background-color: rgb(white, 0.9);
			z-index: 1;
		}
	}

	.knowledge-bytes-container {
		.topics-container {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				inset: 0;
				width: 100vw;
				left: 50%;
				transform: translateX(-50%);
				pointer-events: none;
				border-top: 3px solid $midnight_blue;
			}

			.topics {
				display: grid;
				box-sizing: border-box;
				.topic {
					position: relative;
					display: flex;
					align-items: center;
					column-gap: 20px;
					padding: 30px 0;
					text-decoration: none;
					cursor: pointer;
					img {
						max-width: 40px;
					}

					h4 {
						flex: 1;
					}

					&:before {
						content: '';
						position: absolute;
						inset: 0;
						width: 100vw;
						left: 50%;
						transform: translateX(-50%);
						pointer-events: none;
						border-top: 1px solid #000;
					}

					&:last-child {
						&:before {
							border-bottom: 1px solid #000;
						}
					}
				}
			}
		}

		.topics-container,
		.bytes-container {
			padding-top: 73px;
		}
	}

	.knowledge-byte {
		background-color: $midnight_blue;
		border-radius: 20px;

		.knowledge-byte-title {
			color: white;
		}

		// .knowledge-tags {
		// 	.tag {
		// 		background-color: white;
		// 		color: $blue;
		// 	}
		// }
	}
}

.knowledge-byte {
	text-decoration: none;
	padding: 40px;
	display: flex;
	flex-direction: column;
	gap: 40px;
	position: relative;
	background-size: cover;

	&.show-image:not(.inverted) {
		background-image: url('../../../public/images/knowledgebytes_background.gif');

		&:hover {
			background-image: none;
		}
	}

	border: 3px solid $midnight_blue;
	border-radius: 0;

	min-height: 450px;

	transition: border-radius 0.4s linear, color 0.4s linear, background-color 0.4s linear, border-color 0.4s linear;

	.icon-hover {
		display: none;
	}

	.knowledge-byte-header {

		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		line-height: 10px;
		letter-spacing: 0.1em;
		text-transform: uppercase;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.knowledge-byte-title {
		flex: 1;
	}

	.knowledge-tags {
		display: flex;
		gap: 10px;
		flex-wrap: wrap;
		.tag {
			background-color: white;
			color: $midnight_blue;
		}
	}

	&:hover {
		background-color: $blue;
		border-color: $blue;

		.knowledge-tags {
			.tag {
				background-color: white;
				color: $blue;
			}
		}
	}
}

.knowledge-byte.inverted {
	background-color: white;
	color: $midnight_blue;
	.knowledge-byte-title {
		color: $midnight_blue;
	}
	.knowledge-tags {
		.tag {
			background-color: $midnight_blue;
			color: white;
		}
	}

	.icon {
		display: none;
	}

	.icon-hover {
		display: initial;
	}

	&:hover {
		background-color: $blue;
		border-color: $blue;

		.icon {
			display: initial;
		}

		.icon-hover {
			display: none;
		}

		.knowledge-byte-title {
			color: white;
		}

		.knowledge-tags {
			.tag {
				background-color: white;
				color: $blue;
			}
		}
	}
}

.articles {
	.knowledge-byte {
		background-color: white;
		height: 100%;
		.knowledge-tags {
			.tag {
				background-color: $midnight_blue;
				color: white;
			}
		}

		&:hover {
			color: white;
			background-color: $midnight_blue;
			border-color: $midnight_blue;
			border-radius: 40px;

			.knowledge-byte-title {
				color: white;
			}

			.knowledge-tags {
				.tag {
					background-color: white;
					color: $midnight_blue;
				}
			}
		}
	}
}

.knowledge-byte-detail {
	transition: transform 0.3s linear, opacity 0.3s linear;
	background-color: rgba($midnight_blue, 0.8);
	border: 2px solid white;
	border-radius: 30px;
	padding: 20px 20px 30px 25px;
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	margin-right: 20px;
	position: relative;

	&.answer {
		color: $midnight_blue;
		background-color: white;
	}
	.kb-header {
		display: flex;
		justify-content: flex-end;
	}

	.kb-content {
		@include roboto-light-intro;
		padding-right: 20px;
		display: flex;
		row-gap: 40px;
		flex-direction: column;
	}

	.kb-sidebar {
		position: absolute;
		top: 75px;
		right: -20px;
		display: flex;
		row-gap: 30px;
		flex-direction: column;
		align-items: center;
		.button.next {
			border-width: 2px;
			height: 40px;
			width: 40px;
			padding: 0px;
			border-radius: 50%;
			position: relative;
			&:before,
			&:after {
				position: absolute;
				left: 50%;
				top: 50%;
				translate: -50% -50%;
				content: ' ';
				height: 18px;
				width: 2px;
				background-color: white;
				transition: background-color 0.3s linear;
				border-radius: 2px;
			}

			&:before {
				transform: rotateZ(90deg);
			}

			&:hover {
				&:before,
				&:after {
					background-color: #122444;
				}
				// .icon {
				// 	display: none;
				// }

				// .icon-hover {
				// 	display: initial;
				// }
			}
		}

		.description-text {
			display: none;
		}

		.kb-cards {
			display: flex;
			flex-direction: column;
			row-gap: 5px;
			.kb-card {
				content: '';
				width: 21px;
				height: 31px;
				background-color: $midnight_blue;
				border: 2px solid white;
				border-radius: 2px;
				position: relative;

				&.answer::before {
					content: '';
					background-color: white;
					position: absolute;
					inset: 2px;
				}
			}
		}
	}
}

.byte-header {
	display: flex;
	flex-direction: column;
	padding-top: 80px;
	align-items: center;
	row-gap: 60px;
	.byte-filters {
		display: flex;
		column-gap: 30px;
		flex-wrap: wrap;
		row-gap: 20px;
		.button {
			margin: 0;
			gap: 10px;
			border-radius: 35px;

			.icon,
			.icon-hover {
				height: 25px;
			}

			.icon-hover {
				display: none;
			}

			&:hover {
				.icon {
					display: none;
				}

				.icon-hover {
					display: initial;
				}
			}
		}
	}
}

@include media-breakpoint-up(sm) {
	.knowledge-bytes {
		// .knowledge-bytes-container {
		.bytes {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			column-gap: 20px;

			&.horizontal {
				grid-template-columns: none;
				.knowledge-byte {
					min-width: 50%;
				}
			}
		}
		// .topics-container {
		// }
		// }
	}

	.knowledge-byte-detail {
		padding: 20px 20px 30px 30px;
		margin-right: 36px;
		.kb-content {
			padding-right: 60px;
		}
		.kb-sidebar {
			top: 125px;
			right: -36px;

			.button.next {
				border-width: 3px;
				width: 72px;
				height: 72px;
				&:before,
				&:after {
					height: 34px;
					width: 3px;
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.knowledge-bytes {
		.knowledge-bytes-container {
			.topics-container,
			.bytes-container {
				padding-top: 83px;
			}
		}

		.bytes {
			column-gap: 30px;
			row-gap: 30px;
		}

		.knowledge-bytes-search .search .search-box input {
			font-size: 36px;
			line-height: 43px;
			letter-spacing: 0.03em;
		}
	}

	.knowledge-byte-detail {
		margin-right: 103px;
		padding: 30px 30px 90px 30px;
		.kb-content {
			padding-right: 20px;
		}
		.kb-sidebar {
			top: 0;
			right: -103px;

			.description-text {
				display: initial;
				margin-top: -10px;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.knowledge-bytes {
		.bytes {
			grid-template-columns: repeat(3, minmax(0, 1fr));
			&.horizontal {
				grid-template-columns: none;
				.knowledge-byte {
					min-width: 33%;
				}
			}
		}
		.knowledge-bytes-container {
			display: grid;
			grid-template-columns: 8fr 4fr;
			column-gap: 30px;
			// .bytes-container {
			// }
			.bytes {
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}
			.topics-container {
				border-left: 3px solid $midnight_blue;
				&:before {
					content: initial;
				}

				h3 {
					padding-left: 40px;
				}

				.topics .topic {
					padding-left: 40px;
					&:before {
						width: 100vw;
						left: 0;
						transform: translateX(0);
					}
				}
			}

			.topics-container,
			.bytes-container {
				padding-top: 70px;
			}
		}
	}

	.knowledge-byte-detail {
		margin-right: 150px;
		padding-bottom: 120px;
		padding-left: 60px;
		.kb-content {
			padding-right: 90px;
		}
		.kb-sidebar {
			top: 0;
			right: -150px;

			.button.next {
				width: 120px;
				height: 120px;
				&:before,
				&:after {
					height: 58px;
				}
			}

			.description-text {
				margin-top: 0;
			}
			.kb-cards {
				flex-direction: row;
				column-gap: 5px;
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	.knowledge-bytes {
		.bytes {
			grid-template-columns: repeat(4, minmax(0, 1fr));
			&.horizontal {
				grid-template-columns: none;
				.knowledge-byte {
					min-width: 25%;
				}
			}
		}
		.knowledge-bytes-container {
			column-gap: 150px;
			.topics-container,
			.bytes-container {
				padding-top: 90px;

				.bytes {
					grid-template-columns: repeat(2, minmax(0, 1fr));
					row-gap: 40px;
				}
			}
		}
	}

	.knowledge-byte-detail {
		margin-right: 190px;
		padding-bottom: 120px;
		padding-left: 70px;
		.kb-content {
			padding-right: 90px;
		}
		.kb-sidebar {
			top: 0;
			right: -190px;
			justify-content: center;
			bottom: 0;

			.button.next {
				width: 120px;
				height: 120px;
				&:before,
				&:after {
					height: 58px;
				}
			}

			.kb-cards {
				flex-direction: row;
				column-gap: 5px;
			}
		}
	}
}

@include media-breakpoint-up(xxl) {
	.knowledge-bytes {
		.knowledge-bytes-search .search .search-box input {
			font-size: 40px;
			line-height: 48px;
		}
	}
}

.search-input {
	background-color: transparent !important;
}

.knowledge-byte-detail-container {
	position: relative;

	.knowledge-byte-detail-cards {
		position: absolute;
		inset: 0;
		padding-right: 80px;
		display: flex;
		flex-direction: column;
		row-gap: 20px;
		transition: opacity 0.3s linear, transform 0.3s linear;
		// display: none;
		pointer-events: none;
		opacity: 0;
		transform: translateY(200px);

		.sidebar-button {
			display: none;
		}

		.knowledge-byte {
			border-width: 3px;
			padding: 30px;
			border-radius: 20px;
			border-color: white;
			background-color: $midnight_blue;

			&.inverted {
				background-color: white;
			}

			&:hover {
				background-color: $blue;
				border-color: $blue;
			}
		}
	}

	&.open {
		.knowledge-byte-detail {
			transform: translateX(-50%);
			opacity: 0.5;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				inset: -2px; // compensate borders
				background: linear-gradient(90deg, $midnight_blue 0%, rgba(18, 36, 68, 0) 106.42%);
			}

			.kb-sidebar {
				display: none;
			}
		}

		.knowledge-byte-detail-cards {
			pointer-events: all;
			display: flex;
			transform: translateY(0px);
			opacity: 1;
			// justify-content: center;
			align-items: center;

			.knowledge-byte {
				width: 300px;
			}

			.sidebar-button {
				position: absolute;
				right: 0px;
				top: 75px;
				display: initial;

				.description-text {
					display: none;
				}
				.button.back {
					height: 40px;
					width: 40px;
					padding: 0px;
					border-radius: 50%;
					position: relative;
					border-width: 2px;

					&:before,
					&:after {
						position: absolute;
						left: 50%;
						top: 50%;
						translate: -50% -50%;
						content: ' ';
						height: 18px;
						width: 2px;
						background-color: white;
						transition: background-color 0.3s linear;
						border-radius: 2px;
					}

					&:before {
						transform: rotateZ(45deg);
					}

					&:after {
						transform: rotateZ(-45deg);
					}

					&:hover {
						&:before,
						&:after {
							background-color: $midnight_blue;
						}
					}
				}
			}
		}
	}
}

.description-text {

	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 12px;
	text-align: center;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

@include media-breakpoint-up(sm) {
	.knowledge-byte-detail-container.open .knowledge-byte-detail-cards {
		.knowledge-byte {
			width: 375px;
		}
		.sidebar-button {
			top: 0px;
			.button.back {
				border-width: 3px;
				width: 72px;
				height: 72px;
				&:before,
				&:after {
					height: 34px;
					width: 3px;
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.knowledge-byte-detail-container.open .knowledge-byte-detail-cards {
		.sidebar-button {
			display: flex;
			flex-direction: column;
			row-gap: 20px;
			align-items: center;
			.description-text {
				display: initial;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.knowledge-byte-detail-container .knowledge-byte-detail-cards {
		padding-top: 220px;
		padding-right: 0px;
		flex-direction: row;
		column-gap: 30px;
		align-items: flex-start;
	}

	.knowledge-byte-detail-container.open .knowledge-byte-detail-cards {
		align-items: flex-start;
		.sidebar-button {
			row-gap: 30px;
			.button.back {
				width: 120px;
				height: 120px;
				&:before,
				&:after {
					height: 58px;
				}
			}
		}
	}
}

@include media-breakpoint-up(xl) {
	.knowledge-byte-detail-container .knowledge-byte-detail-cards {
		padding-right: 190px;
		padding-top: 0px;
		align-items: center;
	}
	.knowledge-byte-detail-container.open .knowledge-byte-detail-cards {
		align-items: center;
		.sidebar-button {
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
