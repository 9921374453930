.etp-articles-search-title {
  margin-bottom: 50px;
}

.etp-articles-search-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: clamp(40px, 10vw, 80px);

  .etp-input-group {
    width: 300px;
  }
}

.row.etp-articles {
  row-gap: 60px;
  margin-bottom: 50px;
}