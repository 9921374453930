@use '../../styles/mixins' as *;

.notification-banner {
  padding: 10px 12px;
  position: fixed;
  bottom: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 40px;
  z-index: 2;
  border-radius: 2px;
  font-family: 'alliance_no_1', sans-serif;
  font-style: normal;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  .notification-banner-text {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
}

.notification-banner-link {
  text-decoration: none;
}
