@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

h5 + .graph {
	margin-top: 40px;
}

.graph {
	// padding: 40px 10px;
	// background: rgba($midnight_blue, 0.8);
	// border: 2px solid #ffffff;

	.paragraph {
		padding-right: 0;
	}

	&.market {
		padding: 80px 0 40px;
	}

	@include media-breakpoint-up(lg) {
		// padding: 80px 50px;
		margin-top: 40px;

		// &.market {
		// 	margin-top: -80px;
		// }
	}
}

.indices {
	padding-top: 30px;

	&.row {
		row-gap: 80px;
	}
}

.indice {
	// background: rgba($midnight_blue, 0.8);
	// border: 1px solid rgba(255, 255, 255, 0.7);
	border-bottom: 1px solid $black;
	padding: 0 0 50px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	text-decoration: none;
	width: 100%;
	transition: background-color 0.3s linear, border-radius 0.3s linear, color 0.3s linear;

	.indice-img.inverted {
		display: none;
	}

	h5 {
		padding-top: 35px;
		padding-bottom: 10px;
		display: flex;
    align-items: flex-start;
	}

	img {
		max-width: 100%;
	}

	.indice-header {
		font-size: 16px;
		line-height: 25px;
	}

	.indice-description {
		font-size: 16px;
		line-height: 25px;
		padding-bottom: 10px;
		color: $gray-800;

		// & + .indice-header {
		// 	padding-bottom: 40px;
		// }
	}

	.line-graph {
		border: 1px solid $gray-300;
	}

	.line-graph-container .line-graph .x-axis .tick line,
	.line-graph-container .line-graph .y-axis .tick line {
		stroke: $gray-300;
	}

	.tag {
        transition: background-color 0.3s linear, border-color 0.3s linear, color 0.3s linear;
		background-color: white;
		color: $midnight_blue;
		align-self: flex-start;
        border: 1px solid white;

		& + .indice-header {
			padding-top: 40px;
		}
	}

	.more-info {
		position: relative;
		height: 50px;

		&::before,
		&::after {
			content: '';
			position: absolute;
            transition: border-color 0.3s linear;

		}

		&::before {
			top: 0;
			right: 0;
			border-left: 25px solid white;
			border-top: 25px solid transparent;
			border-bottom: 25px solid transparent;
		}

		&::after {
			right: 2px;
			top: 2px;
			border-left: 23px solid $midnight_blue;
			border-top: 23px solid transparent;
			border-bottom: 23px solid transparent;
		}
	}

	// &:hover {
	// 	background-color: white;
	// 	color: $midnight_blue;
	// 	border-radius: 30px;
	// 	@include media-breakpoint-up(lg) {
	// 		border-radius: 40px;
	// 	}
	// 	cursor: pointer;

	// 	.indice-img {
	// 		display: none;

	// 		&.inverted {
	// 			display: initial;
	// 		}
	// 	}

	// 	.tag {
	// 		border-color: $midnight_blue;
	// 	}

	// 	.more-info {
	// 		&::before {
	// 			border-left-color: $midnight_blue;
	// 		}
	// 		&::after {
	// 			border-left-color: white;
	// 		}
	// 	}

	// 	.tick {
	// 		line {
	// 			stroke: rgba($dark_blue, 0.25) !important;
	// 		}
	// 	}
	// }
}

.tooltip-line {
    opacity: 0;
}

.tooltip {
	position: absolute;
	pointer-events: none;
	white-space: nowrap;
	background-color: $black;
	border: 1px solid $white;
	padding: 8px 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 10px;
	
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	opacity: 0;
	color: $white;

	.white &,
	.gray & {
		background-color: $white;
		border: 1px solid $blue;
		color: $blue;
	}
}

.tooltip-container {
    position: absolute;
    pointer-events: none;

    .tooltip {
        position: relative;
        opacity: 1;
        width: 100%;
    }
}