@use "../../styles/vars" as *;
@use "../../styles/mixins" as *;

.new-branding {

  .new-branding-title {
    margin-bottom: 40px
  }

  .new-branding-video-row {

    .new-branding-video-wrapper {
      position: relative;
      padding-top: 56.25%;
    }

    iframe, .yt-lite {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.new-branding-left-indent {
  padding-left: 28px;
  @include media-breakpoint-up(md) {
    padding-left: 152px;
  }
}

.new-branding-right-indent {
  padding-right: 28px;
  @include media-breakpoint-up(md) {
    padding-right: 152px;
  }
}