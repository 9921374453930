@use '../../styles/vars' as *;
@use '../../styles/mixins' as *;

.quote-container {
	.container:first-child {
		@include widget;
	}

	.etp-quote {
		padding-left: 40px;
		border-left: 1px solid white;
	}

	.quote {
		font-size: 18px;
		line-height: 30px;
		align-items: flex-start
	}

	@include media-breakpoint-up(xs) {
		.quote {
			// padding-left: 20px;
			// border-left: 1px solid white;
			// margin-bottom: 60px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			@include media-breakpoint-up(lg) {
				gap: 40px;
			}
			// .theme-green & {
			// 	border-color: $black;
			// }

			p {
				margin: 0;
			}

			.quote-extra {
				background: $black;
				padding: 30px 40px;
				.investment {
					display: flex;
					flex-direction: column;
					gap: 10px;

					.investment-header {
						font-weight: 500;
						font-size: 10px;
						line-height: 15px;
						letter-spacing: 0.2em;
						@include media-breakpoint-up(lg) {
							font-size: 12px;
							line-height: 20px;
						}
						text-transform: uppercase;
					}

					.investment-amount {
						font-family: 'alliance_no_2';
						font-size: 20px;
						line-height: 30px;

						@include media-breakpoint-up(lg) {
							font-size: 23px;
							line-height: 30px;
						}

					}
				}
			}

			& + .links {
				margin-top: 30px;
				// margin-left: 40px;
			}

			& + .quote-actions {
				margin-top: 40px;
			}
		}
	}

	// @include media-breakpoint-up(sm) {
	// 	.quote {
	// 		padding-left: 40px;
	// 	}
	// }

	@include media-breakpoint-up(lg) {
		// .quote-actions {
		// 	margin-left: 40px;
		// }

		.col-lg-6 + .col-lg-6 {
			// .quote {
			// 	margin-left: -40px;
			// }

			.quote-actions {
				margin-left: 0px;
			}
		}
	}
	.links {
		display: flex;
		gap: 40px;
		flex-wrap: wrap;
	}
}

// .white,
// .light {
// 	.quote-container {
// 		.quote {
// 			border-color: $midnight_blue;
// 		}
// 	}
// }
