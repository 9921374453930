@use '../../styles/vars' as *;

.etp-pagination-controls {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;

  .etp-pagination-controls-chevron:disabled {
    color: $gray-700;
  }

  > * {
    flex: 0 0 38px;
    height: 38px;
  }

  .etp-pagination-controls-ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
  }

  button {
    background-color: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;

    &.etp-pagination-controls-page-btn {
      border: 1px solid transparent;
      font-weight: 600;

      &:disabled {
        border-color: $white;
      }
    }
  }
}

.gray,
.white {

  .etp-pagination-controls-chevron:disabled {
    color: $gray-300;
  }

  .etp-pagination-controls {
    button.etp-pagination-controls-page-btn {

      &:disabled {
        border-color: $black;
      }
    }
  }
}
